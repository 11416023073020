import  {useState,useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import ReactSelectStyle from './ReactSelectStyle';
import ClearIcon from '@mui/icons-material/Clear';

const SelectInput = ({ optionList, isViewOnly, onChangeAction, defaultValue, errorMsg, clearAction, placeholderText, labelText }) => {
 const { t } = useTranslation();


 return (
    <div className="mb-5">
    <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{labelText}</label>
    <div style={{ zIndex: 999999999, display: "flex", alignItems: "center" }}>
      <div style={{flex: 1}}>
    <Select
        options={optionList}
        isDisabled={isViewOnly}
        onChange={(selectedOption) => onChangeAction(selectedOption)}
        classNamePrefix="custom-select"
        value={defaultValue}
        styles={ReactSelectStyle}
        placeholder={placeholderText}
      />
      </div>
      <ClearIcon
        fontSize="medium"
        style={{ cursor: "pointer" }}
        onClick={clearAction}
      />
    </div>
    {errorMsg && (
      <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
        {errorMsg}
      </div>
    )}
  </div>
  );
};

export default SelectInput;
