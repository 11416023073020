import clsx from 'clsx';
import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { colors, titleCss } from '../../../constants/colors';

// Set up the PDF worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfViewerProps {
  fileUrl: string; // Ensure this is a string URL
  fileName: string;
}

export default function PdfViewer({ fileUrl, fileName }: PdfViewerProps) {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1.2); // Scale state for zooming

  const zoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.2, 1.3)); // Limit max zoom to 3x
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.2, 1.1)); // Limit min zoom to 0.5x
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div className="pdf-viewer-container">
      {fileName && fileName !== "" && (
        <div className={clsx("text-center", titleCss)} style={{ color: colors.title }}>
        {fileName}
      </div>
      )}
      <div className="zoom-controls">
        <button onClick={zoomOut}>Zoom Out</button>
        <button onClick={zoomIn}>Zoom In</button>
      </div>
      <div className="pdf-document-container">
        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          renderTextLayer={true}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
      <div className="pagination">
        Page {pageNumber} of {numPages}
      </div>
      <div className="navigation-buttons">
        <button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Previous
        </button>
        <button
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
}
