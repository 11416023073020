import React from 'react';

export default function ImageViewer({ fileUrl, fileName }) {
  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
       {fileName && fileName != "" && fileName}
      <div style={{ flexGrow: 1, overflow: 'auto', border: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* Ensure fileUrl is not null or undefined */}
        {fileUrl ? (
          <img alt="Test Image" src={fileUrl} style={{ maxWidth: '100%', height: 'auto' }} />
        ) : (
          <p>No image available</p>
        )}
      </div>
    </div>
  );
}
