import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {store} from '../../reduxStore/store';
import { saveCompanyAndName, saveLanguage } from "../../reducers/UserConfigReducer";
import { useAuth } from '../modules/auth';
import { updateIsValidRouteForRequestStock } from '../../reducers/indexReducer';


const PrivateRoute =
  {
    "routes": [
      {route:"selectcompany",mniName:"common"},
      {route:"notification",mniName:"common"},
      {route:"dashboard",mniName:"common"},
      {route:"",mniName:"common"},
      {route:"auth",mniName:"common"},
      {route:"logout",mniName:"common"},
      {route:"error",mniName:"common"},
      {route:"selectcompany",mniName:"common"},
      {route:"twoFactorAuth",mniName:"common"},
      {route:"coming-soon",mniName:"common"},
      {route:"requeststock",mniName:"MNI_StockReq_Maintenance"},
      {route:"stockdetail",mniName:"MNI_StockReq_Maintenance"},
      {route:"stock",mniName:"MNI_StockReq_Maintenance"},
      {route:"stock-enquiry",mniName:"MNI_Profile_InvInquiry"},
      {route:"approve-stock-request",mniName:"MNI_StockReq_Authorisation"},
      {route:"create-issue-batch",mniName:"MNI_StockReq_IssueBatch"},
      {route:"close-unprocessed-stock",mniName:"MNI_StockReq_Close"},
      {route:"createuser",mniName:"MNI_UserID"},
      {route:"details",mniName:"MNI_UserID"},
      {route:"system/users",mniName:"MNI_UserID"},
	    {route:"po-enquiry",mniName:"MNI_RepExecutor"},
      {route:"requeststockdev",mniName:"MNI_StockReq_Maintenance"},
      {route:"stockdetaildev",mniName:"MNI_StockReq_Maintenance"},
      {route:"stockdev",mniName:"MNI_StockReq_Maintenance"},
      {route:"stock-enquirydev",mniName:"MNI_Profile_InvInquiry"},
      {route:"approve-stock-requestdev",mniName:"MNI_StockReq_Authorisation"},
      {route:"create-issue-batchdev",mniName:"MNI_StockReq_IssueBatch"},
      {route:"close-unprocessed-stockdev",mniName:"MNI_StockReq_Close"},
      {route:"createuser",mniName:"MNI_UserID"},
      {route:"detailsdev",mniName:"MNI_UserID"},
      {route:"system/usersdev",mniName:"MNI_UserID"},
	    {route:"po-enquirydev",mniName:"MNI_RepExecutor"},
      {route: "invoice",mniName:"MNI_DocManagement_InvReg"},  
      {route:"createInvoice",mniName:"MNI_DocManagement_InvReg"},
      {route:"approveInvoice",mniName:"MNI_DocManagement_InvReg"},
      {route:"costAllocation",mniName:"MNI_DocManagement_InvReg"},
      {route:"createInvoiceBatch",mniName:"MNI_DocManagement_InvReg"},
      {route:"unprocessedInvoice",mniName:"MNI_DocManagement_InvReg"},
      {route:"bankDetailList",mniName:"MNI_Vendor_Bank"},
      {route:"editBankDetails",mniName:"MNI_Vendor_Bank"},
      {route: "bankApprovalList", mniName:"MNI_Vendor_Bank"},
      {route: "plantEnquiry", mniName: "MNI_Plant_Inquiry"}
    ]
  }

const RouteInterceptor = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  
  const { clearLocalStorage } =useAuth();
  const allMniNames = useSelector((state: any) => state?.menuConfig?.mniList); 
  // navigate to auth if menuData is empty and clean up the local storage
  if (!allMniNames || allMniNames.length === 0) {
      store?.dispatch(saveCompanyAndName({code:"",name:""}))
      store?.dispatch(saveLanguage("en"));
      store?.dispatch(updateIsValidRouteForRequestStock(false));
      clearLocalStorage();
    return <Navigate to="/auth" />;                
  }


  let hasAccess = false;
  const hasMniName = (currentRoute) => {
    // Extract the mniName of the current route
    const routeMniName = PrivateRoute.routes.find(route => route.route === currentRoute)?.mniName;
    
    if(routeMniName==='common'){
      return true;
    }
    // Check if routeMniName exists in allMniNames
    return allMniNames.includes(routeMniName);
    };

  hasAccess=hasMniName(currentPath.substring(1));



  if (!hasAccess) {
    return <Navigate to="/unauthorized" />;
  }

  if(currentPath!='/requeststock' && currentPath!='/requeststockdev' ){
    store?.dispatch(updateIsValidRouteForRequestStock(false));
  }

  return <>{children}</>;
};

export default RouteInterceptor;
