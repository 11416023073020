import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../user/UsersTable.css";
import {
  approveInvoice,
  closeInvoice,
  createInvoiceBatch,
  deleteInvoice,
  fetchinvoiceList,
  processInvoice,
  rejectInvoice,
  submitInvoice,
  uploadInvoice,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { KTCard, KTSVG } from "../../../theme/helpers";
import { useAuth } from "../../modules/auth";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import { useTranslation } from "react-i18next";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { useSelector } from "react-redux";
import AddFolder from "../../../assets/add-folder.png"
import File from "../../../assets/file.png"
import { Popup } from 'devextreme-react/popup';
import UploadFile from "../../modules/custom components/uploadFile";
import { Row} from "react-bootstrap";
import { Field, Form, Formik } from 'formik';
import { DeleteModal } from "../../modules/widgets/components/DeleteModal";
import DevDynamicGridTable from "../PO-Stock-Enquiry/DevDynamicGridTable";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import CustomSSELoader from "../user/CustomSSELoader";
import { URLS } from "../../utils/URLS";
import Tooltip from "@mui/material/Tooltip";

const InvoiceListing = ({type}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [commentVisibilityFlag, setCommentVisibilityFlag] = useState(false)
  const [commentValue, setCommentValue] = useState('');
  const [actionType, setActionType] = useState('');
  const { setDeleteid, deleteid ,currentUser } = useAuth();
  const currentPath = window.location.pathname;

  const [tableConfiguration, setTableConfiguration] = useState<any>();
  const [tabStorage, setTableStorage] = useState();
  const [tabSqlName, setTabSqlName] = useState();

  const company = useSelector((state: any) => state?.userConfig?.company);
  const fileExtensions = ['.jpg', '.jpeg', '.gif', '.png', '.pdf'].join(',');

  const [option ,setOption]=useState([]);
  const [columnDefs, setColumnDefs] = useState<any>([]);
  const [popupFlag, setPopupFlag] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedDataRow, setSelectedDataRow] = useState([]);
  const [isToggled, setIsToggled] = useState(false)
  const eventSourceRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [loaderText, setLoaderText] = useState<any>([])
  const [enableGridCustomization, setEnableGridCustomization] = useState<Boolean>(false)

  const ERP_API_URL = URLS.REACT_APP_API_URL;


  useEffect(() => {
    fetchInvoiceListAPI();
  }, [isToggled]);

  useEffect(() => {
    return () => {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
        }
    };
}, []);

  const fetchInvoiceListAPI = async()=>{
    setTrackLoadingState(true);
    try {
      const response = await fetchinvoiceList(company, type, isToggled ? "all" : "default")
      const array2: string[] = [];
        if(response && response.errorCode === 0 && response.data){
          const processedData = Array.isArray(response.data.data) 
          ? response.data.data.map((item) => {
              // Create a new object where each key has its original value or an empty string if null or undefined
              const newItem = {};
              Object.keys(item).forEach((key) => {
                newItem[key] = item[key] ?? ' ';
              });
              return newItem;
            })
          : [];
          setRowData(processedData);
          setTableConfiguration(response.data.tableDefinition);
          setEnableGridCustomization(response.data.customizeGridEnabled)
          setTableStorage(response.data.storageName)
          setTabSqlName(response.data.sqlName)
          setOption(response.data.menuName)
          setCommentValue('')
          const columnDef = [
            ...response.data.columnDefinitions,
            {
              "infoType": "column",
                  "columnName": "actions",
                  "columnType": "Char",
                  "caption": "Actions",
                  "defaultCaption": "Actions",
                  "lastSavedCaption": "Actions",
                  "alignment": "left",
                  "defaultAlignment": "left",
                  "lastSavedAlignment": "left",
                  "columnIndex": response.data.columnDefinitions.length+1,
                  "defaultColumnIndex": response.data.columnDefinitions.length+1,
                  "lastSavedcolumnIndex": response.data.columnDefinitions.length+1,
                  "columnVisible": true,
                  "defaultColumnVisible": true,
                  "lastSavedColumnVisible": true,
                  "numericFormat": null,
                  "defaultNumericFormat": null,
                  "lastSavedNumericFormat": null,
                  "summaryFooterFunction": null,
                  "defaultSummaryFooterFunction": null,
                  "lastSavedSummaryFooterFunction": null,
                  "currency": null,
                  "defaultCurrency": null,
                  "lastSavedcurrency": null,
                  "colId": "action",
                  "width": 150,
                  "defaultWidth": 150,
                  "lastSavedWidth": 150,
                  "hide": false,
                  "defaultHide": false,
                  "lastSavedHide": false,
                  "pinned": true,
                  "defaultPinned": true,
                  "lastSavedPinned": true,
                  "pinnedPosition": "right",
                  "defaultPinnedPosition": null,
                  "lastSavedPinnedPosition": null,
                  "sort": null,
                  "defaultSort": null,
                  "lastSavedSort": null,
                  "defaultSortIndex": null,
                  "lastSavedSortIndex": null,
                  "aggFunc": null,
                  "defaultAggFunc": null,
                  "lastSavedAggFunc": null,
                  "rowGroup": false,
                  "defaultRowGroup": false,
                  "lastSavedRowGroup": false,
                  "rowGroupIndex": null,
                  "defaultRowGroupIndex": null,
                  "lastSavedRowGroupIndex": null,
                  "pivot": false,
                  "defaultPivot": false,
                  "lastSavedPivot": false,
                  "pivotIndex": null,
                  "defaultPivotIndex": null,
                  "lastSavedPivotIndex": null,
                  "flex": 1,
                  "defaultFlex": 1,
                  "lastSavedFlex": 1,
                  "amount": false,
                  "lastRestoreCustomLayout": false, 
              }            
          ];
          setColumnDefs(columnDef);
          
        } else {
          toastMessage(response?.errorMsg,"error");
        }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  };


  const uploadFileAction = () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append("companyCode", company);
    if(selectedFile) {
      formData.append("file", selectedFile[0]);
    }
    uploadInvoice(formData)
    .then((response) => {
        console.log('Invoice upload request sent', response);
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
        }
        if (response && response.errorCode === 0) {
          setSelectedFile(null);
          setLoaderText([]);
          navigate('/createInvoice', { state: { invoiceId: response.data, previousPage: 'create' } });
        } else {
          if (response.errorMsg) {
            toastMessage(t(response.errorMsg), "error");
          } else {
            toastMessage(t("An error occured Please try again later."), "error");
          }
        }
    })
    .catch((error) => {
        console.error('Error:', error);
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
            setLoaderText([]);
        }
    })
    .finally(() => {
      setPopupFlag(false)
      setIsLoading(false)
        setTimeout(() => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                setLoaderText([]);
            }
        }, 1000);
    });

    // Initialize SSE connection with a 2-second delay
    setTimeout(() => {
        console.log("Listening to SSE updates");
        eventSourceRef.current = new EventSource(`${ERP_API_URL}/stream`);

        eventSourceRef.current.onmessage = (event) => {
            console.log("SSE Message:", event.data);
            // toastMessage(event.data, "success")
            setLoaderText((prevLoaderText) => [...prevLoaderText, event.data]);
        };

        eventSourceRef.current.onerror = () => {
            console.error('SSE Connection Error');
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                setLoaderText([]);
            }
        };
    }, 2000); // 2-second delay
};

  const onSelectedFilesChanged = (e) => {
    setSelectedFile(e.value);
  }

  const setSeletedRow = (array) => {
    const srArray = array.map((item) => (item.seqNo));
    setSelectedDataRow(srArray);
  }

  const onSubmitAction = async () => {
    try {
      setTrackLoadingState(true);

      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await submitInvoice(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchInvoiceListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const onApproveAction = async () => {
    try {
      setTrackLoadingState(true);

      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await approveInvoice(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchInvoiceListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");
        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const onProcessInvoiceAction = async () => {
    
    try {
      setTrackLoadingState(true);

      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await processInvoice(company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchInvoiceListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const onIssueBatch = async () => {
    try {
      setTrackLoadingState(true);

      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await createInvoiceBatch(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchInvoiceListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const onCloseAction = async () => {
    try {
      setTrackLoadingState(true);

      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await closeInvoice(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchInvoiceListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const onRejectAction = async () => {
    try {
      setTrackLoadingState(true);

      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }
      const response = await rejectInvoice(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchInvoiceListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const commentAdded = () => {
    setCommentVisibilityFlag(false)
    if(actionType === 'submitInvoice') {
      onSubmitAction()
    }else if (actionType === 'approve') {
      onApproveAction()
    } else if(actionType === 'create') {
      onIssueBatch()
    }  else if (actionType === 'close') {
      onCloseAction()
    } else {
      onRejectAction()
    }
  }

  const renderContent = () => {
    return (
    <Formik
    initialValues={{ commentValue: commentValue}}
    onSubmit={(values) => commentAdded()}
  >
    {({ handleChange, handleBlur }) => (
      <Form>
        <Field
          as="textarea"
          name="description"
          placeholder="Comment"
          className="form-control"
          style={{ minHeight: '100px' }}
          value={commentValue}
          onChange={(e) => {
            setCommentValue(e.target.value);
            handleChange(e); // Important to call Formik's handleChange
          }}
          onBlur={handleBlur} // Ensure Formik's blur handling
        />
        <div className="col py-3">
        <button
          className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
          title={t('Submit')}
          style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
          {t('Submit')}
        </button>
      </div>      
    </Form>
    )}
  </Formik>
    )
  }

  const openComment = (value) => {
    setCommentVisibilityFlag(true);
    setActionType(value);
  }

  const deleteInvoiceAction = async () => {
    try {
      const invoiceId = localStorage.getItem("deleteInvoiceNo");
      const response = await deleteInvoice(invoiceId, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        setDeleteid(false);
        fetchInvoiceListAPI();
      } else {
        toastMessage(t("An error occured Please try again later."),"error");
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
  }

  const AddOption = () => (
    <>
      {type === 'create' && (
        <div className="d-flex flex-wrap gap-4 align-items-center mr-3">
          <button
            className="btn text-white rounded-pill fs-6 primary-button"
            onClick={() => navigate('/createInvoice', { state: { invoiceId: 0, previousPage: 'create' } })}
            title={t('New')}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#0a7eac",
              minWidth: "150px",
              flexShrink: 0, // Ensures button maintains size in small screens
            }}
          >
            <FaPlus style={{ marginRight: "5px" }} size={15} />
            {t('New')}
          </button>

          <Tooltip title="Upload Invoice">
            <img
              src={File}
              style={{ height: "40px", width: "50px", cursor: "pointer", flexShrink: 0 }}
              onClick={() => setPopupFlag((prev) => !prev)}
              alt="File icon"
            />
          </Tooltip>

          <Tooltip title="Upload from Default Folder">
            <img
              src={AddFolder}
              style={{ height: "40px", width: "50px", cursor: "pointer", flexShrink: 0 }}
              onClick={() => onProcessInvoiceAction()}
              alt="Add Folder icon"
            />
          </Tooltip>

          {/* Toggle Button Group */}
          <ToggleButtonGroup
            color="primary"
            value={isToggled}
            exclusive
            onChange={() => setIsToggled((prev) => !prev)}
            aria-label="Platform"
            className="d-flex flex-grow-1 flex-wrap gap-1"
          >
            <ToggleButton value={false} sx={{ minWidth: 100, textAlign: "center" }}>
              Default
            </ToggleButton>
            <ToggleButton value={true} sx={{ minWidth: 100, textAlign: "center" }}>
              All Data
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )} </> );
  return (
    <>
    {isLoading && <CustomSSELoader messageArray={loaderText}/>}
      <div>
        <div
          className=" d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">

        {trackLoadingState?(<CustomLoadingCellRender/>):(
           <DevDynamicGridTable
             type={option}
             tabTitle={'Invoice'}
             tabSqlData={rowData}
             tabStorageData={columnDefs}
             tabStorage={tabStorage}
             tabSqlName={tabSqlName}
             tableConfiguration={tableConfiguration}
             tabId={'0'}
             fetchOthersTabData={fetchInvoiceListAPI}
             customizeGridEnabled={enableGridCustomization}
             onSelectionChanged={(e) => setSeletedRow(e.selectedRowKeys)}
             extraAction={AddOption}
           />
       )}
      
      <Row className="mt-5 mb-5 pt-15" style={{ display: "flex", justifyContent: "center" }}>
      {
        selectedDataRow && selectedDataRow.length > 0 &&
          <>
          <div className="col-md-4">
            {
              type === 'create' &&
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={() => openComment('submitInvoice')}
                title={t('Submit')}
                style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                {t('Submit')}
              </button>
            }
            {
              type === 'approve' &&
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={() => openComment('approve')}
                title={t('Approve')}
                style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                {t('Approve')}
              </button>
            }
            {
              type === 'issue' &&
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={() => openComment('create')}
                title={t('Create invoice batch')}
                style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                {t('Create invoice batch')}
              </button>
            }
            {
              type === 'close' && 
              <button
              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
              onClick={() => openComment('close')}
              title={type === 'issue' ? t('Issue Batch') : t('Approve')}
              style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
              {t('Close')}
            </button>
            }
        </div>
        {
          type ==='approve' &&
          <div className="col-md-2">
            <button
              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
              onClick={() => openComment('reject')}
              title={t('Reject')}
              style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
              {t('Reject')}
            </button>
          </div>
        }
          </>
      }
      </Row>
      {
        type === 'create' &&
        <UploadFile
        visibilityFlag={popupFlag}
        title="Upload invoice"
        onHideFunction={() => {setPopupFlag(prev => !prev)}}
        multipleFlag={false}
        fileExtensions={fileExtensions}
        onSelectedFilesChanged={onSelectedFilesChanged}
        onUploadAction={uploadFileAction}/> 
      }
        <Popup
        visible={commentVisibilityFlag}
        hideOnOutsideClick={true}
        onHiding={(e) => setCommentVisibilityFlag(false)}
        showCloseButton={true}
        resizeEnabled={true}
        title={'Comment'}
        width={335}
        height={280}
        contentRender={renderContent}/>
      
      {deleteid && (
        <DeleteModal
          title={t("Are you sure you want to delete this line(s)?")}
          onDelete={deleteInvoiceAction}
          onCancel={()=>setDeleteid(false)}
        />
      )}
      </KTCard>
     
    </>
  );
};

export default InvoiceListing;
