import axios from "axios";
import { ID} from "../../../../../../theme/helpers";
import * as authHelper from "../../../../auth/core/AuthHelpers";
import { toastMessage } from "../../../../auth/functions/toastMessage";
import { EncUtil } from "../../../../../hooks/EncUtil";
import { URLS } from "../../../../../utils/URLS";

const ERP_API_URL = URLS.REACT_APP_API_URL;



// ERP API CALL😃

const GET_STOCK = `${ERP_API_URL}/stocklist`;
const GET_VENDER = `${ERP_API_URL}/getallvendors`;
const GET_AUTH_GROUP = `${ERP_API_URL}/getauthgroups`;
const GET_AUTH_BY = `${ERP_API_URL}/selectauthgroup`;
const SELECT_STOCK_CODE = `${ERP_API_URL}/selectstockitem`;
const GET_PIN_FAV = `${ERP_API_URL}/getpinsandfavourites`;
const SELECT_WORK_CODE = `${ERP_API_URL}/selectworkorderno`;
const SELECT_GL_ACCOUNT = `${ERP_API_URL}/getglaccountsbycodes`;
const GET_AUTH_GROUPS = `${ERP_API_URL}/getauthgroup`;
const GET_AUTHORIZED_BY = `${ERP_API_URL}/selectauthgroup`;
const GET_STOCK_ITEMS = `${ERP_API_URL}/getstockitems`;
const GET_STOCK_DETAIL = `${ERP_API_URL}/selectstockitem`;
const ADD_STOCK = `${ERP_API_URL}/saveorupdatestock`;
const GET_JOB_CODE = `${ERP_API_URL}/getjobcode`;
const GET_REQ_CODE = `${ERP_API_URL}/selectphasecode`;
const GET_PHASE_CODE = `${ERP_API_URL}/selectjobcode`;
const GET_PHASE = `${ERP_API_URL}/selectjobcode`;
const UNLOCK_USER = `${ERP_API_URL}/unlockuseraccount`;
const DOWNLOAD_STOCK_LIST = `${ERP_API_URL}/downloadcsv`;
const DOWNLOAD_PAY_SLIP = `${ERP_API_URL}/pickslipdownload`;
const GET_COMPANY_LIST = `${ERP_API_URL}/companylist`;
const SELECT_COMPANY_LIST = `${ERP_API_URL}/selectcompany`;
const LOGOUT = `${ERP_API_URL}/logoutuser`;
const GET_WORK_ORDER_NO = `${ERP_API_URL}/getworkorderno`;
const CHANGE_STATUS = `${ERP_API_URL}/changestatus`;
const EDIT_STOCK = `${ERP_API_URL}/editstock`;
const SELECT_PHASE = `${ERP_API_URL}/selectphase`;
const COMPANY_LIST = `${ERP_API_URL}/companylist`;
const MODULE_LIST = `${ERP_API_URL}/modulelist`;
const ADD_COMPANY = `${ERP_API_URL}/createcompany`;
const GET_USER_LIST = `${ERP_API_URL}/userlist`;
const GET_USERS = `${ERP_API_URL}/users`;
const GET_USERSBYID = `${ERP_API_URL}/getuserbyid`;
const SAVE_NOTIFICATIONS = `${ERP_API_URL}/saveorupdatenotifications`;
const GEN_PICKUP_SLIP = `${ERP_API_URL}/generatepickslip`;
const GET_Notification = `${ERP_API_URL}/getnotifications`;
const GET_POENQUIRY = `${ERP_API_URL}/getinquiryjsonforapplication`;
const GET_POENQUIRY_DEFAULT = `${ERP_API_URL}/getinquirywithdefaultdata`;
const GET_STOCKENQUIRY = `${ERP_API_URL}/getinquiryjsonforapplication`;
const GET_STOCKENQUIRY_DEFAULT = `${ERP_API_URL}/getinquirywithdefaultdata`;
const GET_POENQUIRYOTHERTABS = `${ERP_API_URL}/getdataforapplicationtab`;
const GET_MODIFY_COLUMN = `${ERP_API_URL}/modifycolumn`;
const GET_MODIFY_TABLE = `${ERP_API_URL}/modifytable`;
const GET_RESET_COLUMN = `${ERP_API_URL}/resetlayout`;
const GET_RESTORE_LAYOUT = `${ERP_API_URL}/restorelayout`;
const GET_SUMMARY_FOOTER = `${ERP_API_URL}/savesummaryfooter`;
const GET_STOCKENQUIRYOTHERTABS = `${ERP_API_URL}/getdataforapplicationtab`;
const GET_STOCK_LOOKUP = `${ERP_API_URL}/getstockitems`;
const DELETE_Stock = `${ERP_API_URL}/deletestockrequest`;
const GET_STOCK_LISTING = `${ERP_API_URL}/getstockrequestlist`;
const GET_STOCK_REQUEST_BY_NO = `${ERP_API_URL}/getstockrequestbyno`;
const GET_LINEITEM_LISTING = `${ERP_API_URL}/getlineitems`;
const LOCK_REQUESTG = `${ERP_API_URL}/lockrequestno`;
const APPROVE_STOCK_REQUEST = `${ERP_API_URL}/approverequest`;
const REJECT_STOCK_REQUEST = `${ERP_API_URL}/rejectstock`;
const REAUTHORISE_STOCK_REQUEST = `${ERP_API_URL}/reauthorise`;
const SAVE_USER = `${ERP_API_URL}/saveuser`;
const SAVE_USER_MENU = `${ERP_API_URL}/saveusermenu`;
const VERIFY_USER = `${ERP_API_URL}/verifyuserid`;
const GET_COMPANY_BY_USER = `${ERP_API_URL}/getcompany`;
const GET_COMPANY_BY_USER_FROM = `${ERP_API_URL}/getcompanybyuser`;
const GET_ALL_COMPANY = `${ERP_API_URL}/getallcompanys`;
const CHANGE_PASSWORD = `${ERP_API_URL}/changepassword`;
const VERIFY_MENU_ACCESS = `${ERP_API_URL}/verifyusermenuaccess`;
const RESET_PASSWORD = `${ERP_API_URL}/resetpassword`;
const REFRESH_TOKEN = `${ERP_API_URL}/refreshtoken`;
const RESEND_TFA = `${ERP_API_URL}/resendtfa`;
const SET_PREFERENCE = `${ERP_API_URL}/setpreference`;
const FORGOT_PASSWORD = `${ERP_API_URL}/forgotpassword`;
const GET_MENUS = `${ERP_API_URL}/getmenus`;
const GET_MENUS_BY_USER = `${ERP_API_URL}/getsidebarmenu`;
const GET_EQUV_USER_ID = `${ERP_API_URL}/users`;
const Delete_user = `${ERP_API_URL}/deleteuser`;
const GET_RULES = `${ERP_API_URL}/rules`;
const GET_USERMENU_DET = `${ERP_API_URL}/getusermenudet`;
const SAVE_USERMENU_DET = `${ERP_API_URL}/copyaccess`;
const CLOSE_request = `${ERP_API_URL}/closerequest`;
const ISSUEBATCH = `${ERP_API_URL}/issuebatch`;
const CHANGE_LANGUAGE = `${ERP_API_URL}/defaultlanguage`;
const GET_PRIORITY = `${ERP_API_URL}/getpriority`;
const GET_STOCK_ITEM = `${ERP_API_URL}/getstockitems`;
const SAVE_PINS_FAV = `${ERP_API_URL}/savepinsandfavourites`;
const GET_Location_ITEM = `${ERP_API_URL}/getlocation`;
const GET_STOCK_NUMBER = `${ERP_API_URL}/fetchrequestno`;
const GET_USER_DROPDOWN = `${ERP_API_URL}/getsaveuserdropdowns`;
const SAVE_STOCK_REQUEST = `${ERP_API_URL}/saveorupdatestock`;
const SAVE_STOCK_REQUEST_V1 = `${ERP_API_URL}/v1/saveorupdatestock`;
const DELETE_STOCK_REQUEST_LINE_ITEMS = `${ERP_API_URL}/deletelineitemsbyreq`;
const GET_STOCK_STATUS = `${ERP_API_URL}/getstatus`;
const GET_WORK_NO = `${ERP_API_URL}/getworkorderno`;
const GET_REQ_STOCK_ALL_DATA = `${ERP_API_URL}/getexpensegroup`;
const GET_GL_ACCOUNT = `${ERP_API_URL}/getglaccounts`;
const INVOICE = `${ERP_API_URL}/getinvoices`;
const UPLOAD_INVOICE = `${ERP_API_URL}/uploadinvoice`;
const GET_INVOICE_DETAILS = `${ERP_API_URL}/getinvoicebyid`;
const UPLOADATTACHMENTS = `${ERP_API_URL}/saveattachments`;
const DELETE_ATTACHMENT = `${ERP_API_URL}/deleteattachments`;
const VIEW_INVOICE = `${ERP_API_URL}/viewInvoice`;
const GET_VENDOR_DROPDOWN = `${ERP_API_URL}/getvendordropdown`;
const GET_PO_DROPDOWN = `${ERP_API_URL}/getpodropdown`;
const SAVE_OR_UPDATE_INVOICE = `${ERP_API_URL}/saveorupdateinvoice`;
const EDIT_INVOICE = `${ERP_API_URL}/editinvoice`;
const APPROVE_INVOICE = `${ERP_API_URL}/approveinvoice`;
const REJECT_INVOICE = `${ERP_API_URL}/rejectinvoice`;
const GET_DOC = `${ERP_API_URL}/document/view`;
const GET_JOB_CODE_LIST = `${ERP_API_URL}/getjobcodes`; 
const GET_PHASE_CODE_LIST = `${ERP_API_URL}/getphasecodes`;
const GET_REQ_CODE_LIST = `${ERP_API_URL}/getreqcodes`;
const DELETE_INVOICE = `${ERP_API_URL}/deleteinvoice`;
const GET_COST_ALLOCATION = `${ERP_API_URL}/getcostdata`;
const SAVE_COST_ALLOCATION = `${ERP_API_URL}/savecostdata`;
const CREATE_INVOICE_BATCH = `${ERP_API_URL}/issueinvoice`;
const SUBMIT_INVOICES = `${ERP_API_URL}/submitinvoice`;
const CLOSE_INVOICE = `${ERP_API_URL}/closeinvoice`;
const GET_UERS_DROPDOWN = `${ERP_API_URL}/getuserdropdown`;
const PO_DETAILS = `${ERP_API_URL}/getpoinfo`;
const BANK_LIST = `${ERP_API_URL}/getbanks`;
const CHANGE_VENDOR_BANK = `${ERP_API_URL}/changevendorbank`;
const SAVE_BANK_TRANSACTION = `${ERP_API_URL}/savebanktransaction`;
const APPROVE_BANK_DETAILS = `${ERP_API_URL}/approvebankdetail`;
const REJECT_BANK_DETAILS = `${ERP_API_URL}/rejectbankdetail`;
const GET_BANK_DETAILS_BY_ID = `${ERP_API_URL}/getbankdetailbyid`;
const GET_COUNTRY_LIST = `${ERP_API_URL}/getcountrydropdown`;
const SUBMIT_BANK_DETAILS = `${ERP_API_URL}/submitbankdetail`;
const DELETE_BANK_DETAILS = `${ERP_API_URL}/deletebankdetail`;
const GET_AUTHORIZERS_LIST = `${ERP_API_URL}/getbankdauthorizers`;
const PROCESSINVOICE = `${ERP_API_URL}/processinvoice`;
const GET_ALL_NOTIFICATION =  `${ERP_API_URL}/getoverallnotifications`;
const GET_GST_DROPDOWN = `${ERP_API_URL}/getgstdropdown`;
const GET_AUTH_GROUP_TREE = `${ERP_API_URL}/getAuthTree`;
const SAVE_BANK_ATTACHMENTS = `${ERP_API_URL}/bank/saveattachments`;
const DELETE_BANK_ATTACHMENT = `${ERP_API_URL}/bank/deleteattachments`;
const COPY_INVOICE = `${ERP_API_URL}/copyinvoice`;
const CURRENCY_VALUE = `${ERP_API_URL}/getcurrencydropdown`;
const GET_LINCKED_COMPANy = `${ERP_API_URL}/getlinkedcompanies`;
const GET_GL_DATA = `${ERP_API_URL}/getglcodes`;

const auth = authHelper.getAuth();

const checkAuthTokenExpiry = (e) => {
  console.debug("checkAuthTokenExpiry", e, e?.response?.data);
  if(e?.response?.status == 400){
    if(e?.response?.data?.errorMsg){
      toastMessage(e?.response?.data?.errorMsg, "error");
    }else{
      toastMessage(e?.response?.data?.data, "error");
    }
  }
};


const getDoc = (path: string): any => {
  return axios
    .post(
      `${GET_DOC}`,
      { path },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          Accept: '*/*'
        },
        responseType: 'blob',
      }
    )
    .then((response) => {
      return response;
    })
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};


const getVendor = (country?: string): any => {
  return axios
    .get(`${GET_VENDER}?companyCode=${country}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      console.debug("api error in STOCK LISTING", e);
    });
};

// ERP API CALL

const getStockList = (query?: string): any => {
  return axios
    .get(`${GET_STOCK}${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      console.debug("api error in STOCK LISTING", e);
    });
};
const getAuthGroups = (): any => {
  return axios
    .get(`${GET_AUTH_GROUP}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getCompanyList = (): any => {
  return axios
    .get(`${GET_COMPANY_LIST}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getWorkOrderNo = (): any => {
  return axios
    .get(`${GET_WORK_ORDER_NO}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getReqCodes = (
  phaseCode: any,
  code: any,
  search: any,
  jobCode: any,
  filteredData: any
): any => {
  return axios
    .post(
      `${GET_REQ_CODE}/${
        phaseCode ? phaseCode : Date.now()
      }?companyCode=${code}&searchItem=${search}&jobCode=${jobCode}`,
      filteredData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getPhaseCodes = (
  jobCode: any,
  code: any,
  search: any,
  filteredData: any
): any => {
  return axios
    .post(
      `${GET_PHASE_CODE}/${
        jobCode ? jobCode : Date.now()
      }?companyCode=${code}&searchItem=${search}`,
      filteredData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const unLockUserAcc = (userId: any): any => {
  return axios
    .post(
      `${UNLOCK_USER}?userId=${userId}`,

      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getJobCodes = (code: any, search: any, filteredData: any): any => {
  return axios
    .post(
      `${GET_JOB_CODE}?companyCode=${code}&searchItem=${search}`,
      filteredData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getPhase = (query: string): any => {
  return axios
    .get(`${GET_PHASE}/${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getAuthBy = (query: string): any => {
  return axios
    .get(`${GET_AUTHORIZED_BY}/${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getStockItems = (): any => {
  return axios
    .get(`${GET_STOCK_ITEMS}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getStockDetail = (query: string): any => {
  return axios
    .get(`${GET_STOCK_DETAIL}/${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};

const addStockFunc = async (stockData: any) => {
  return axios
    .post(ADD_STOCK, stockData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const editStockFunc = async (params: string, stockData: any) => {
  return axios
    .post(`${EDIT_STOCK}/${params}`, stockData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const changeStatus = async (query: any) => {
  return axios
    .post(
      `${CHANGE_STATUS}${query}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const addCompany = async (companyData: any) => {
  return axios
    .post(`${ADD_COMPANY}`, companyData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const selectPhase = async (query: any) => {
  return axios
    .get(`${SELECT_PHASE}${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getUserList = async (query: any) => {
  return axios
    .get(`${GET_USER_LIST}${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const companyList = async (query: any) => {
  return axios
    .get(`${COMPANY_LIST}${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const moduleList = async (query: any) => {
  return axios
    .get(`${MODULE_LIST}${query}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const downloadStockList = async () => {
  return axios
    .get(DOWNLOAD_STOCK_LIST, { responseType: "blob" })
    .then((response) => {
      // Creating a blob from the response data
      const blob = new Blob([response.data], { type: "application/csv" });

      // Creating a URL for the blob
      const fileURL = window.URL.createObjectURL(blob);

      // Creating an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "stocklist.csv";

      // Triggering the download
      link.click();

      // Cleanup: Revoke the object URL to free up resources
      window.URL.revokeObjectURL(fileURL);
    })
    .catch((error) => {
      console.error("Error downloading PDF:", error);
    });
};

const downloadPaySlip = async (reqNo: string) => {
  return axios
    .get(`${DOWNLOAD_PAY_SLIP}?reqNo=${reqNo}`, { responseType: "blob" })
    .then((response) => {
      // Creating a blob from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Creating a URL for the blob
      const fileURL = window.URL.createObjectURL(blob);

      // Creating an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "pickslip.pdf";

      // Triggering the download
      link.click();

      // Cleanup: Revoke the object URL to free up resources
      window.URL.revokeObjectURL(fileURL);
    })
    .catch((error) => {
      console.error("Error downloading CSV:", error);
    });
};


const deleteUser = (userId: ID): Promise<void> => {
  console.debug("DELETE URL - ", `${Delete_user}/${userId}`);
  return axios
    .delete(`${Delete_user}/${userId}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response) => {
      console.debug(response.data); // Log the response data
    })
    .catch((error) => {
      console.error("API error:", error);
      throw error; // Re-throw the error to propagate it if needed
    });
};



const getRules = (): any => {
  return axios
    .get(GET_RULES, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((res: any) => res.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const deletestock = async (reqNo: any, company) => {
  return axios
    .delete(`${DELETE_Stock}/${reqNo}?companyCode=${company}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const selectCompany = (companyName?: string): any => {
  return axios
    .post(`${SELECT_COMPANY_LIST}/${companyName}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e) => e.toJSON());
};

// CLASSIC CALLS

const logoutUsers = (token) => {
  return axios
    .post(LOGOUT, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response: any) => response.status)
    .catch((e: any) => e.response.status);
};
const getUser = (search, filterData, page = "1", size = "10"): any => {
  return axios
    .post(
      `${GET_USERS}`,
      filterData,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
      // throw e;
    });
};
const getUserById = (userId): any => {
  return axios
    .get(
      `${GET_USERSBYID}?userId=${userId}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
      // throw e;
    });
};

const getStockLookUp = (company, search: any, filteredData: any): any => {
  console.debug(
    "stkkkkkkk",
    `${GET_STOCK_LOOKUP}?companyCode=${company}&searchItem=${search}`
  );
  return axios
    .post(
      `${GET_STOCK_LOOKUP}?companyCode=${company}&searchItem=${search}`,
      filteredData,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
      // throw e;
    });
};

const getStockRequestList = (
  user,
  company,
  filterData,
  search,
  status,
  action = "create",
  dropdown = "all"

  // page = "1",
  // size = "10",
): any => {
  return axios
    .post(
      `${GET_STOCK_LISTING}?userId=${user}&companyCode=${company}&searchItem=${search}&action=${action}&status=${status}&dropdown=${dropdown}`,
      // `${GET_STOCK_LISTING}?searchItem=${search}&pageNo=${page}&pageSize=10000000`,
      filterData,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
      // throw e;
    });
};
const getstockrequestbyno= (company, requestNo): any => {
  return axios
    .get(`${GET_STOCK_REQUEST_BY_NO}?companyCode=${company}&requestNo=${requestNo}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
      // throw e;
    });

}
const getLineitemList = (company, requestNo, filterData): any => {
  return axios
    .post(
      `${GET_LINEITEM_LISTING}?companyCode=${company}&requestNo=${requestNo}`,
      // `${GET_STOCK_LISTING}?searchItem=${search}&pageNo=${page}&pageSize=10000000`,
      filterData,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
      // throw e;
    });
};

const lockRequest = (payload): any => {
  const { company, requestNo, selected } = payload;

  return axios
    .post(
      `${LOCK_REQUESTG}?companyCode=${company}&requestNo=${requestNo}&checked=${selected}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
      // throw e;
    });
};

const approveStockRequest = (company, data): any => {
  return axios
    .post(`${APPROVE_STOCK_REQUEST}?companyCode=${company}`, data, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const rejectStockRequest = (company, data): any => {
  return axios
    .post(`${REJECT_STOCK_REQUEST}?companyCode=${company}`, data, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};

const reauthoriseStockRequest = (userData): any => {
  try {
    var params = new URLSearchParams();
    params.append("userId", userData.userName);
    params.append("password", EncUtil.encrypt(userData.password));
    return axios({
      method: "post",
      url: REAUTHORISE_STOCK_REQUEST,
      data: params,
    });
  } catch (e) {
    checkAuthTokenExpiry(e);
  }
};

// const saveUser = async (userData) => {
// 	console.debug(userData, "hiiiiii");
// 	try {
// 		const response = await api.post(`${SAVE_USER}`, userData, {
// 			headers: {
// 				Authorization: `Bearer ${auth?.token}`,
// 			},
// 		});
// 		return response.data;
// 	} catch (error) {
// 		throw error;
// 	}
// };

const saveUser = (userData) => {
  return axios
    .post(SAVE_USER, userData, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const saveUserMenu = (userData) => {
  return axios
    .post(SAVE_USER_MENU, userData, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const changePassword = (userData) => {
  try {
    var params = new URLSearchParams();
    params.append("userId", userData.userId);
    params.append("oldPassword", EncUtil.encrypt(userData.oldPassword));
    params.append("newPassword", EncUtil.encrypt(userData.newPassword));
    params.append("userEmail", userData.userEmail || "");
    return axios({
      method: "post",
      url: CHANGE_PASSWORD,
      data: params,
    });
  } catch (error) {
    checkAuthTokenExpiry(error);
  }
};
const verifyMenuAccess = (userData) => {
  try {
    var params = new URLSearchParams();
    params.append("compCode", userData.compCode);
    params.append("applCode", userData.applCode);
    params.append("mniName", userData.mniName);
    return axios({
      method: "post",
      url: VERIFY_MENU_ACCESS,
      data: params,
    });
    
  } catch (error) {
    console.debug("checkAuthTokenExpiry error in menuaccess", error);
    checkAuthTokenExpiry(error);
  }
};

const resetPassword = (userData) => {
  var params = new URLSearchParams();
  params.append("userId", userData.userId.userId);
  params.append("newPassword", EncUtil.encrypt(userData.newPassword));
  params.append("email", userData.userId.userEmail);
  return axios({
    method: "post",
    url: RESET_PASSWORD,
    data: params,
    // headers: { Authorization: `Bearer ${auth?.token}` },
  });
};
// const refreshToken = (token) => {
//   // console.debug("refreshToken", token);

//   var params = new URLSearchParams();
//   params.append("refreshToken", token);

//   return fetch(REFRESH_TOKEN, {
//     method: "POST",
//     body: params
//   })
//   .then(response => response.json()) // Parse response JSON
//   .then(data => {
//     // Handle the data as needed
//     return data;
//   });
// };
const refreshToken = (token) => {

  var params = new URLSearchParams();
  params.append("refreshToken", token);

  // Use axios to send a POST request with the URL and data
  return axios({
    method: "post", // Specifies the HTTP method
    url: REFRESH_TOKEN, // The URL endpoint for refreshing the token
    data: params, // The request body containing the URLSearchParams
  })
    .then((response) => response.data) // Parse the response to extract the data
    .then((data) => {
      // You can perform additional operations with the data if needed
      return data; // Return the processed data
    })
    .catch((err)=> {
      return err;
    }
    )
};

const setPreference = (userData, userId = "") => {
  console.debug("HHEELLLLLL", { userId });
  var params = new URLSearchParams();
  params.append("platform_email", userData.platform_email);
  params.append("doNotAskAgain", userData.doNotAskAgain);
  params.append("platform", userData.platform);
  params.append("userId", userId);
  return axios({
    method: "post",
    url: SET_PREFERENCE,
    data: params,
  });
};
const forgotPassword = (userId, email = "") => {
  console.debug("forgotPassword");
  var params = new URLSearchParams();
  params.append("userId", userId);
  params.append("email", email);
  return axios({
    method: "post",
    url: FORGOT_PASSWORD,
    data: params,
  });
};
const resendTfa = (token) => {
  var params = new URLSearchParams();
  params.append("userId", token);

  return axios({
    method: "post",
    url: RESEND_TFA,
    data: params,
  });
};
const isUserExist = (userId) => {
  return axios
    .get(`${VERIFY_USER}?userId=${userId}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getAuthGroup = (code,requestedBy): any => {
  console.debug("hhhhhhhhhhhhhhhhh", `${GET_AUTH_GROUPS}?companyCode=${code}&userId=${requestedBy}`);
  return axios
    .get(`${GET_AUTH_GROUPS}?companyCode=${code}&userId=${requestedBy}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getAuthGroupby = (code, group): any => {
  console.debug("yyyyyyyyy", `${GET_AUTH_BY}?companyCode=${code}`);
  return axios
    .get(`${GET_AUTH_BY}/${group}?companyCode=${code}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};
const getCompanyWithUserId = () => {
  return axios
    .get(`${GET_COMPANY_BY_USER}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => e.response);
};
const getCompanyWithUserIdFrom = (userId) => {
  return axios
    .get(`${GET_COMPANY_BY_USER_FROM}?userId=${userId}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getLinkedCompanyList = (comapanyCode) => {
  return axios
  .get(`${GET_LINCKED_COMPANy}?companyCode=${comapanyCode}`, {
    headers: { Authorization: `Bearer ${auth?.token}` },
  })
  .then((response: any) => response.data)
  .catch((e: any) => checkAuthTokenExpiry(e));
}

const getAllCompany = () => {
  return axios
    .get(`${GET_ALL_COMPANY}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getMenus = (filteredData, userId) => {
  console.debug(
    "url",
    `${GET_MENUS}`
  );
  return axios
    .post(
      `${GET_MENUS}?userId=${userId}`,
      filteredData,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getMenuByUser = (code) => {
  return axios
    .get(`${GET_MENUS_BY_USER}?companyCode=${code}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getEquivalentUsersId = async (stockData: any) => {
  return axios
    .post(GET_USERS, stockData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const saveOrUpdateNotifications = async (stockData: any) => {
  return axios
    .post(
      `${SAVE_NOTIFICATIONS}?isEditCase=${stockData.isEditCase}&assignTo=${stockData.assignTo}&companyCode=${stockData.companyCode}&description=${stockData.description || ""}&url=${stockData.url || ""}&mniName=${stockData.mniName}&requestedBy=${stockData.requestedBy}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const generatePickupSlip = async (stockData: any, data: any) => {
  return axios
    .post(
      `${GEN_PICKUP_SLIP}?companyCode=${stockData.companyCode}&generateDate=${stockData.generateDate}&generateTime=${stockData.generateTime}&reqNo=${stockData.reqNo}&status=${stockData.status}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    )

    .then((response: any) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "pickslip.pdf";
      link.click();
      window.URL.revokeObjectURL(fileURL);
    })
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getNotification = (code, search, mni) => {
  return axios
    .get(
      `${GET_Notification}?companyCode=${code}&searchItem=${search}&mniName=${mni}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getPOEnquiry = (code, applicationCode, startDate, endDate) => {
  return axios
    .get(
      `${GET_POENQUIRY}?companyCode=${code}&applicationCode=${applicationCode}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getPOEnquiryDefault = (code, applicationCode, startDate, endDate) => {
  return axios
    .get(
      `${GET_POENQUIRY_DEFAULT}?companyCode=${code}&applicationCode=${applicationCode}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getPOEnquiryOthersTabs = (code, applicationCode, poNumber,tabId) => {
  return axios
    .get(
      `${GET_POENQUIRYOTHERTABS}?companyCode=${code}&applicationCode=${applicationCode}&poNumber=${poNumber}&tabId=${tabId}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getModifycolumn = (modifiedData) => {
  return axios
    .post(`${GET_MODIFY_COLUMN}?companyCode=${modifiedData?.companyCode}`, modifiedData, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getModifyTable = (modifiedData) => {
  return axios
    .post(`${GET_MODIFY_TABLE}?companyCode=${modifiedData?.companyCode}`, modifiedData, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getResetColumn = (data: any) => {
  return axios
    .post(`${GET_RESET_COLUMN}?companyCode=${data?.companyCode}&tabStorage=${data.storageTab}&tabSqlName=${data?.tabSqlName}`, data.payload, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getRestoreCustomizeLayout = (data: any) => {
  return axios
    .post(`${GET_RESTORE_LAYOUT}?companyCode=${data?.companyCode}&tabStorage=${data.storageTab}&tabSqlName=${data?.tabSqlName}`, data.payload, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const saveSummaryFooter = (data: any) => {
  return axios
    .post(`${GET_SUMMARY_FOOTER}?companyCode=${data?.companyCode}`, data, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getStockEnquiry = (code, applicationCode, startDate, endDate) => {
  return axios
    .get(
      `${GET_STOCKENQUIRY}?companyCode=${code}&applicationCode=${applicationCode}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getStockEnquiryDefault = (code, applicationCode, startDate, endDate) => {
  return axios
    .get(
      `${GET_STOCKENQUIRY_DEFAULT}?companyCode=${code}&applicationCode=${applicationCode}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getStockOthersTabs = (code, applicationCode, poNumber,tabId) => {
  return axios
    .get(
      `${GET_STOCKENQUIRYOTHERTABS}?companyCode=${code}&applicationCode=${applicationCode}&poNumber=${poNumber}&tabId=${tabId}`,
      {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const saveUserMenudet = async (
  menuData: any,
  user: any,
  fromCompany: any,
  toCompany: any
) => {
  return axios
    .post(
      `${SAVE_USERMENU_DET}?userId=${user}&fromCompany=${fromCompany}&toCompany=${toCompany}`,
      menuData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const closerequest = async (Company: any, closeData: any) => {
  return axios
    .post(`${CLOSE_request}?&companyCode=${Company}`, closeData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const issuebatch = async (Company: any, closeData: any) => {
  return axios
    .post(`${ISSUEBATCH}?&companyCode=${Company}`, closeData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const changeLanguage = async (language: any) => {
  return axios
    .post(`${CHANGE_LANGUAGE}?language=${language}`, null, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getStockNumber = async (code: string) => {
  return axios
    .post(`${GET_STOCK_NUMBER}?companyCode=${code}`, null, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getsaveuserdropdowns = async (code: string) => {
  return axios
    .post(`${GET_USER_DROPDOWN}?companyCode=${code}`, null, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const createStockRequest = async (code: string, data: any) => {
  return axios
    .post(`${SAVE_STOCK_REQUEST}?companyCode=${code}`, data, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const createStockRequestV1 = async (code: string, data: any) => {
  return axios
    .post(`${SAVE_STOCK_REQUEST_V1}?companyCode=${code}`, data, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const deleteStockRequestLineItems = async(code: string, data: any)=>{
  return axios
    .delete(`${DELETE_STOCK_REQUEST_LINE_ITEMS}?companyCode=${code}`, {data, 
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const getStockStatus = async (code: string) => {
  return axios
    .get(`${GET_STOCK_STATUS}?companyCode=${code}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getworkorderno = async (code: string, search: any, filteredData: any) => {
  return axios
    .post(
      `${GET_WORK_NO}?companyCode=${code}&searchItem=${search}`,
      filteredData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getReqStockAllData = async (code: string) => {
  return axios
    .post(
      `${GET_REQ_STOCK_ALL_DATA}?companyCode=${code}`,{},
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getGlAccount = async (code: string, search: any, filteredData: any) => {
  return axios
    .post(
      `${GET_GL_ACCOUNT}?companyCode=${code}&searchItem=${search}`,
      filteredData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getPriority = async (code: string) => {
  return axios
    .get(`${GET_PRIORITY}?companyCode=${code}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const getLocation = async (
  code: string,
  stock: string,
  search: any,
  filteredData: any
) => {
  console.debug(
    "urllll",
    `${GET_Location_ITEM}/${stock}?companyCode=${code}&searchItem=${search}`
  );
  return axios
    .post(
      `${GET_Location_ITEM}/${stock}?companyCode=${code}&searchItem=${search}`,
      filteredData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
// const getLocation = async (code: string,stock: string, search: any, filteredData: any) => {
//   console.debug("urllll",`${GET_Location_ITEM}/${stock}?companyCode=${code}&searchItem=${search}`)
// 	return axios
// 		.post(
// 			`${GET_Location_ITEM}/${stock}?companyCode=${code}&searchItem=${search}`,
// 			filteredData,
// 			{
// 				headers: {
// 					Authorization: `Bearer ${auth?.token}`,
// 				},
// 			}
// 		)
// 		.then((response: any) => response.data)
// 		.catch((e: any) => checkAuthTokenExpiry(e));
// };
const getStockItem = async (code: string, search: any, filteredData: any) => {
  return axios
    .post(
      `${GET_STOCK_ITEM}?companyCode=${code}&searchItem=${search}`,
      filteredData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const savePinsFav = async (data: any) => {
  return axios
    .post(
      `${SAVE_PINS_FAV}?companyCode=${data.companyCode}&userId=${data.userId}&applicationCode=${data.applicationCode}&mniName=${data.mniName}&pinOrFav=${data.pinOrFav}&pinOrFavStatus=${data.pinOrFavStatus}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getPinOrFav = async (data: any) => {
  return axios
    .get(`${GET_PIN_FAV}?companyCode=ZTE&userId=ADITYA`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const selectStockCodes = async (code: string, group) => {
  return axios
    .get(`${SELECT_STOCK_CODE}/${group}?companyCode=${code}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
// MK00000002?companyCode=ZTE&stockCode=RREW
//SELECT_WORK_CODE
//companyCode=ZTE&jobCode=MLV&phaseCode=MAIN&reqCode=1003
const selectcodes = async (code: string, jobCode, phaseCode, reqCode) => {
  console.debug(
    "url",
    `${SELECT_GL_ACCOUNT}/?companyCode=${code}&jobCode=${jobCode}&phaseCode=${phaseCode}&reqCode=${reqCode}`
  );
  return axios
    .get(
      `${SELECT_GL_ACCOUNT}/?companyCode=${code}&jobCode=${jobCode}&phaseCode=${phaseCode}&reqCode=${reqCode}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};
const selectWorkorder = async (code: string, group, order) => {
  console.debug(
    "url",
    `${SELECT_WORK_CODE}/${order}?companyCode=${code}&stockCode=${group}`
  );
  return axios
    .get(
      `${SELECT_WORK_CODE}/${order}?companyCode=${code}&stockCode=${group}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getUserMenudet = (userId, companyCode): any => {
  console.debug(
    `${GET_USERMENU_DET}?userId=${userId}&companyCode=${companyCode}`
  );

  return axios
    .get(`${GET_USERMENU_DET}?userId=${userId}&companyCode=${companyCode}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    })
    .then((d) => d.data)
    .catch((e) => {
      checkAuthTokenExpiry(e);
    });
};

const fetchinvoiceList = (companyCode, action, data): any => {
  console.debug("url",`${INVOICE}?companyCode=${companyCode}&action=${action}&data=${data}`);
  return axios
    .post(
      `${INVOICE}?companyCode=${companyCode}&action=${action}&data=${data}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const uploadInvoice = (payload): any => {
  console.debug("url",`${UPLOAD_INVOICE}`);
  return axios
    .post(
      `${UPLOAD_INVOICE}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          Accept: '*/*'
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};    

const getInvoiceById = (company, id): any => {
  console.debug("url",`${GET_INVOICE_DETAILS}?companyCode=${company}&seqNo=${id}`);
  return axios
    .post(
      `${GET_INVOICE_DETAILS}?companyCode=${company}&seqNo=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};    

const uploadAttachments = (payload): any => {
  console.debug("url",`${UPLOADATTACHMENTS}`);
  return axios
    .post(
      `${UPLOADATTACHMENTS}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const deleteAttachment = (company: string, seqNo: string, payload: any) => {
  console.debug("url", `${DELETE_ATTACHMENT}?companyCode=${company}&seqNo=${seqNo}`);
  return axios
    .delete(`${DELETE_ATTACHMENT}?companyCode=${company}&seqNo=${seqNo}`, {
      data: payload,
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const viewInvoice = (company: string, invoiceId: string): any => {
  console.debug("url", `${VIEW_INVOICE}/${invoiceId}?companyCode=${company}`);
  return axios
    .post(
      `${VIEW_INVOICE}/${invoiceId}?companyCode=${company}`, 
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          Accept: `*/*`,
        },
        responseType: 'blob',
      }
    )
    .then((response: any) => response)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getvendordropdown = (company: string): any => {
  console.debug("url", `${GET_VENDOR_DROPDOWN}?companyCode=${company}`);
  return axios
    .get(`${GET_VENDOR_DROPDOWN}?companyCode=${company}`, 
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const getpodropdown = (company: string, vendorCode: string): any => {
  console.debug("url", `${GET_PO_DROPDOWN}/${vendorCode}?companyCode=${company}`);
  return axios
    .get(`${GET_PO_DROPDOWN}/${vendorCode}?companyCode=${company}`, 
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const saveorUpdateInvoice = (formData: any): any => {
  console.debug("url", `${SAVE_OR_UPDATE_INVOICE}`);
  return axios
    .post(`${SAVE_OR_UPDATE_INVOICE}`, 
      formData,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const editInvoiceDocument = (payload: any, seqNo: string) => {
  console.debug("url", `${EDIT_INVOICE}/${seqNo}`)
  return axios
    .put(
      `${EDIT_INVOICE}/${seqNo}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          Accept: '*/*'
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const approveInvoice = (payload: any, company: string) => {
  console.debug("url", `${APPROVE_INVOICE}?companyCode=${company}`)
  return axios
    .post(
      `${APPROVE_INVOICE}?companyCode=${company}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const rejectInvoice = (payload: any, company: string) => {
  console.debug("url", `${REJECT_INVOICE}?companyCode=${company}`)
  return axios
    .post(
      `${REJECT_INVOICE}?companyCode=${company}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const getJobCodeList = (company: string) => {
  console.debug("url", `${GET_JOB_CODE_LIST}?companyCode=${company}`)
  return axios
    .get(
      `${GET_JOB_CODE_LIST}?companyCode=${company}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const getPhaseOptionByjobCode = (company: string, jobCode: string) => {
  console.debug("url", `${GET_PHASE_CODE_LIST}/${jobCode}?companyCode=${company}`)
  return axios
    .get(
      `${GET_PHASE_CODE_LIST}/${jobCode}?companyCode=${company}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const getReqOptionByjobCode = (company: string, jobCode: string, phaseCode: string) => {
  console.debug("url", `${GET_REQ_CODE_LIST}/${phaseCode}?companyCode=${company}&jobCode=${jobCode}`)
  return axios
    .get(
      `${GET_REQ_CODE_LIST}/${phaseCode}?companyCode=${company}&jobCode=${jobCode}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const deleteInvoice = (invoiceId: any, company: string) => {
  console.debug("url", `${DELETE_INVOICE}/${invoiceId}?companyCode=${company}`)
  return axios
    .delete(
      `${DELETE_INVOICE}/${invoiceId}?companyCode=${company}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const getCostAllocation = (invoiceId: any, company: string) => {
  console.debug("url", `${GET_COST_ALLOCATION}/${invoiceId}?companyCode=${company}`)
  return axios
    .get(
      `${GET_COST_ALLOCATION}/${invoiceId}?companyCode=${company}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}



const saveCostAllocation = (payload: any, invoiceId: any, company: string, action: string) => {
  console.debug("url", `${SAVE_COST_ALLOCATION}/${invoiceId}?companyCode=${company}&action=${action}`)
  return axios
    .put(
      `${SAVE_COST_ALLOCATION}/${invoiceId}?companyCode=${company}&action=${action}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          Accept: '*/*'
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const createInvoiceBatch = (payload: any, company: string) => {
  console.debug("url", `${CREATE_INVOICE_BATCH}?companyCode=${company}`)
  return axios
    .post(
      `${CREATE_INVOICE_BATCH}?companyCode=${company}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const submitInvoice = (payload: any, company: string) => {
  console.debug("url", `${SUBMIT_INVOICES}?companyCode=${company}`)
  return axios
    .post(
      `${SUBMIT_INVOICES}?companyCode=${company}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const closeInvoice = (payload: any, company: string) => {
  console.debug("url", `${CLOSE_INVOICE}?companyCode=${company}`)
  return axios
    .post(
      `${CLOSE_INVOICE}?companyCode=${company}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const getUsersDropDown = () => {
  console.debug("url", `${GET_UERS_DROPDOWN}`)
  return axios
    .get(
      `${GET_UERS_DROPDOWN}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const getPODetails = (poNumber: any, company: string) => {
  console.debug("url", `${PO_DETAILS}`)
  return axios
    .get(
      `${PO_DETAILS}/${poNumber}?companyCode=${company}`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const fetchBankList = (companyCode, action, data): any => {
  console.debug("url",`${BANK_LIST}?companyCode=${companyCode}&action=${action}&data=${data}`);
  return axios
    .post(
      `${BANK_LIST}?companyCode=${companyCode}&action=${action}&data=${data}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
};

const changeVendorBank = (companyCode: string, vendorCode: string) => {
  console.debug("url",`${CHANGE_VENDOR_BANK}/${vendorCode}?companyCode=${companyCode}`);
  return axios
    .post(
      `${CHANGE_VENDOR_BANK}/${vendorCode}?companyCode=${companyCode}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
}

const savebanktransaction = (companyCode: string, payload: any) => {
  console.debug("url",`${SAVE_BANK_TRANSACTION}?companyCode=${companyCode}`);
  return axios
    .post(
      `${SAVE_BANK_TRANSACTION}?companyCode=${companyCode}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    )
    .then((response: any) => response.data)
    .catch((e: any) => checkAuthTokenExpiry(e));
  }
  

  const approvebankdetail = (companyCode: string, payload: any) => {
    console.debug("url",`${APPROVE_BANK_DETAILS}?companyCode=${companyCode}`);
    return axios
      .post(
        `${APPROVE_BANK_DETAILS}?companyCode=${companyCode}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((response: any) => response.data)
      .catch((e: any) => checkAuthTokenExpiry(e));
    }

  const rejectbankdetail = (companyCode: string, payload: any) => {
    console.debug("url",`${REJECT_BANK_DETAILS}?companyCode=${companyCode}`);
    return axios
      .post(
        `${REJECT_BANK_DETAILS}?companyCode=${companyCode}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((response: any) => response.data)
      .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const getBankDetailsById = (companyCode: string, seqNo: string) => {
      console.debug("url",`${GET_BANK_DETAILS_BY_ID}?companyCode=${companyCode}&seqNo=${seqNo}`);
    return axios
      .post(
        `${GET_BANK_DETAILS_BY_ID}?companyCode=${companyCode}&seqNo=${seqNo}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((response: any) => response.data)
      .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const getcountryList = (companyCode: string) => {
      console.debug("url",`${GET_COUNTRY_LIST}?companyCode=${companyCode}`);
      return axios
        .get(
          `${GET_COUNTRY_LIST}?companyCode=${companyCode}
          `,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((response: any) => response.data)
        .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const submitBankDetails = (companyCode: string, payload: any) => {
      console.debug("url",`${SUBMIT_BANK_DETAILS}?companyCode=${companyCode}`);
    return axios
      .post(
        `${SUBMIT_BANK_DETAILS}?companyCode=${companyCode}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((response: any) => response.data)
      .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const deleteBankDetail = (companyCode: string, payload: any) => {
      console.debug("url", `${DELETE_BANK_DETAILS}?companyCode=${companyCode}`);
      return axios
        .delete(`${DELETE_BANK_DETAILS}?companyCode=${companyCode}`, {
          data: payload,
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response: any) => response.data)
        .catch((e: any) => checkAuthTokenExpiry(e));
    };

    const getbankdauthorizers = (companyCode: string) => {
      console.debug("url",`${GET_AUTHORIZERS_LIST}?companyCode=${companyCode}`);
    return axios
      .get(
        `${GET_AUTHORIZERS_LIST}?companyCode=${companyCode}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((response: any) => response.data)
      .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const processInvoice = (companyCode: string) => {
      console.debug("url",`${PROCESSINVOICE}?companyCode=${companyCode}`);
      return axios
        .post(
          `${PROCESSINVOICE}?companyCode=${companyCode}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((response: any) => response.data)
        .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const getAuthGroupForInvoice  = (code,requestedBy): any => {
      console.debug(`${GET_AUTH_GROUPS}?companyCode=${code}&userId=${requestedBy}&applCode=AP`);
      return axios
        .get(`${GET_AUTH_GROUPS}?companyCode=${code}&userId=${requestedBy}&applCode=AP`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((d) => d.data)
        .catch((e) => {
          checkAuthTokenExpiry(e);
        });
    };

    const getAllNotificationData  = (code): any => {
      console.debug(`${GET_ALL_NOTIFICATION}?companyCode=${code}`);
      return axios
        .get(`${GET_ALL_NOTIFICATION}?companyCode=${code}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((d) => d.data)
        .catch((e) => {
          checkAuthTokenExpiry(e);
        });
    };

    const getGstDetails = (code): any => {
      console.debug(`${GET_GST_DROPDOWN}?companyCode=${code}`);
      return axios
        .get(`${GET_GST_DROPDOWN}?companyCode=${code}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((d) => d.data)
        .catch((e) => {
          checkAuthTokenExpiry(e);
        });
    };

    const getAuthGroupTreeData = (authGroup, code): any => {
      console.debug(`${GET_AUTH_GROUP_TREE}/${authGroup}?companyCode=${code}`);
      return axios
        .get(`${GET_AUTH_GROUP_TREE}/${authGroup}?companyCode=${code}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((d) => d.data)
        .catch((e) => {
          checkAuthTokenExpiry(e);
        });
    };

    const saveBankAttachment = (payload): any => {
      
      console.debug(`${SAVE_BANK_ATTACHMENTS}`);
      return axios
        .post(
          `${SAVE_BANK_ATTACHMENTS}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((response: any) => response.data)
        .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const deleteBankAttachment = (seqNo, company, payload): any => {
      console.debug("url", `${DELETE_BANK_ATTACHMENT}?companyCode=${company}&seqNo=${seqNo}`);
      return axios
        .delete(`${DELETE_BANK_ATTACHMENT}?companyCode=${company}&seqNo=${seqNo}`, {
          data: payload,
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response: any) => response.data)
        .catch((e: any) => checkAuthTokenExpiry(e));
    };

    const copyInvoice = (seqNo, company): any => {
      
      console.debug("url",`${COPY_INVOICE}/${seqNo}?companyCode=${company}`);
      return axios
        .post(
          `${COPY_INVOICE}/${seqNo}?companyCode=${company}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((response: any) => response.data)
        .catch((e: any) => checkAuthTokenExpiry(e));
    }

    const getCurrencyOption = (): any => {
      console.debug(`${CURRENCY_VALUE}`);
      return axios
        .get(`${CURRENCY_VALUE}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((d) => d.data)
        .catch((e) => {
          checkAuthTokenExpiry(e);
        });
    };

    const getGLData = async (code: string) => {
      return axios
        .get(
          `${GET_GL_DATA}?companyCode=${code}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((response: any) => response.data)
        .catch((e: any) => checkAuthTokenExpiry(e));
    };

export {
  deleteUser,
  getDoc,
  getStockList,
  getAuthBy,
  getAuthGroups,
  getAuthGroup,
  getStockItems,
  getStockDetail,
  addStockFunc,
  getJobCodes,
  getReqCodes,
  getPhaseCodes,
  unLockUserAcc,
  getPhase,
  downloadStockList,
  getCompanyList,
  getWorkOrderNo,
  getReqStockAllData,
  getGlAccount,
  changeStatus,
  editStockFunc,
  selectCompany,
  logoutUsers,
  downloadPaySlip,
  selectPhase,
  companyList,
  moduleList,
  addCompany,
  getUserList,
  getUser,
  getUserById,
  saveUser,
  isUserExist,
  getCompanyWithUserId,
  getCompanyWithUserIdFrom,
  getAllCompany,
  changePassword,
  verifyMenuAccess,
  resendTfa,
  setPreference,
  forgotPassword,
  getMenus,
  resetPassword,
  saveUserMenu,
  refreshToken,
  getMenuByUser,
  getEquivalentUsersId,
  saveOrUpdateNotifications,
  getRules,
  getUserMenudet,
  saveUserMenudet,
  changeLanguage,
  getStockRequestList,
  getstockrequestbyno,
  lockRequest,
  approveStockRequest,
  rejectStockRequest,
  reauthoriseStockRequest,
  getPriority,
  getStockNumber,
  createStockRequest,
  deleteStockRequestLineItems,
  getStockStatus,
  getStockItem,
  getAuthGroupby,
  selectStockCodes,
  getStockLookUp,
  getworkorderno,
  selectWorkorder,
  getLocation,
  getNotification,
  getPOEnquiry,
  getPOEnquiryDefault,
  getPOEnquiryOthersTabs,
  getModifycolumn,
  getModifyTable,
  getResetColumn,
  getRestoreCustomizeLayout,
  saveSummaryFooter,
  getStockEnquiry,
  getStockEnquiryDefault,
  getStockOthersTabs,
  savePinsFav,
  getPinOrFav,
  generatePickupSlip,
  deletestock,
  closerequest,
  issuebatch,
  getLineitemList,
  getVendor,
  selectcodes,
  getsaveuserdropdowns,
  createStockRequestV1,
  fetchinvoiceList,
  uploadInvoice,
  getInvoiceById,
  uploadAttachments,
  deleteAttachment,
  viewInvoice,
  getvendordropdown,
  getpodropdown,
  saveorUpdateInvoice,
  editInvoiceDocument,
  approveInvoice,
  rejectInvoice,
  getJobCodeList,
  getPhaseOptionByjobCode,
  getReqOptionByjobCode,
  deleteInvoice,
  getCostAllocation,
  saveCostAllocation,
  createInvoiceBatch,
  submitInvoice,
  closeInvoice,
  getUsersDropDown,
  getPODetails,
  fetchBankList,
  changeVendorBank,
  savebanktransaction,
  rejectbankdetail,
  getBankDetailsById,
  getcountryList,
  submitBankDetails,
  deleteBankDetail,
  approvebankdetail,
  getbankdauthorizers,
  processInvoice,
  getAuthGroupForInvoice,
  getAllNotificationData,
  getGstDetails,
  getAuthGroupTreeData,
  saveBankAttachment,
  deleteBankAttachment,
  copyInvoice,
  getCurrencyOption,
  getLinkedCompanyList,
  getGLData
};
