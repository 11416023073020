import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CustomLoaders from '../user/CustomLoaders';
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Button, IconButton, TextField } from '@mui/material';
import { toastMessage } from '../../modules/auth/functions/toastMessage';
import { useSelector } from 'react-redux';
import { approveInvoice, closeInvoice, createInvoiceBatch, deleteAttachment, editInvoiceDocument, getGstDetails, getAuthGroup, getAuthGroupby, getAuthGroupForInvoice, getCostAllocation, getDoc, getInvoiceById, getPhaseOptionByjobCode, getPODetails, getpodropdown, getReqOptionByjobCode, getStockStatus, getUsersDropDown, getvendordropdown, rejectInvoice, saveCostAllocation, saveorUpdateInvoice, uploadAttachments, uploadInvoice, viewInvoice, getAuthGroupTreeData, getCurrencyOption } from '../../modules/apps/user-management/users-list/core/_requests';
import { colors, titleCss } from "../../../constants/colors";
import ReactSelectStyle from "../../utils/ReactSelectStyle";
import Select from "react-select";
import * as Yup from 'yup';
import { format, parse, isDate } from 'date-fns';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFile from "../../modules/custom components/uploadFile";
import List from 'devextreme-react/list';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { Document, Page } from 'react-pdf';
import { CheckBox } from 'devextreme-react/check-box';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import PdfViewer from "../../modules/custom components/PdfViewer";
import ImageViewer from "../../modules/custom components/ImageViewer";
import NumberBox from 'devextreme-react/number-box';
import { Popup } from 'devextreme-react/popup';
import { FaPlus } from "react-icons/fa";
import { useAuth } from "../../modules/auth";
import DataGrid, { Column, Editing, HeaderFilter, Lookup, SearchPanel, Summary, TotalItem, ValidationRule } from "devextreme-react/cjs/data-grid";
import { TreeList } from 'devextreme-react/tree-list';
import Box from '@mui/material/Box';
import { cleanNumber, formatPrice } from "../../utils/Config";
import SelectInput from "../../utils/SelectInput";

interface LocationState {
  invoiceId?: any;
  isViewOnly?: any;
  previousPage?: any;
}

export default function CreateInvoice() {

  const location = useLocation();
  const { invoiceId: initialInvoiceId } = (location.state as LocationState) || {};
  const { isViewOnly } = location.state as LocationState || false;
  const { previousPage } = location.state as LocationState || 'create';
  const company = useSelector((state: any) => state?.userConfig?.company);
  const headerRef: any = useRef(null);
  const path = window.location.href.includes("/stockdetail");
  const { currentUser } = useAuth();
  const [userEmail, userId, userName]: any = currentUser?.sub?.split("#");


  const { t } = useTranslation();
  const fileExtensions = ['.jpg', '.jpeg', '.png', '.pdf'].join(',');

  const [loading, setLoading] = useState(false);
  const [statusOption, setStatusOption] = useState<any>([]);
  const [authgroupOption, setAuthgroupOption] = useState<any>([]);
  const [authorizedByOption, setAuthorizedByOption] = useState<any>([]);
  const [uploadFileVisibility, setUploadFileVisibility] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [attachmentList, setAttachmentList] = useState<any>([]);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedAttachmentArray, setSelectedAttachmentArray] = useState<any>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [documentVisibility, setDocumentVisibility] = useState<boolean>(true);
  const [documentType, setDocumentType] = useState<string>('');
  const [vendorOption, setVendorOption] = useState<any>([]);
  const [vendorCodeNameMapper, setVendorCodeNameMapper] = useState<any>([]);
  const [poOPtion, setPoOPtion] = useState<any>([]);
  const [uploadInvoiceDocVisibilityFlag, setUploadInvoiceDocVisibilityFlag] = useState(false);
  const [selectedInvoiceDoc, setSelectedInvoiceDoc] = useState<any>()
  const [invoiceId, setInvoiceId] = useState(initialInvoiceId);
  const [commentVisibilityFlag, setCommentVisibilityFlag] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const [actionType, setActionType] = useState('');
  const [costAllocationData, setCostAllocationData] = useState<any>([]);
  const [readAuthGroupFlag, setReadAuthGroupFlag] = useState(false);
  const [readAuthByFlag, setReadAuthByFlag] = useState(false);
  const [totalCostAlocationAmount, setTotalCostAllocationAmount] = useState(0);
  const [phaseOption, setPhaseOption] = useState([]);
  const [reqOption, setReqOption] = useState([]);
  const [costAllocationSaveButton, setCostAllocationSaveButton] = useState(false);
  const [authGroupVisibility, setAuthGroupVisibility] = useState(false);
  const [gstOption, setGstOption] = useState<any>([]);
  const [gstRespose, setGstResponse] = useState<any>([]);
  const [authGroupData, setAuthGroupData] = useState<any>([]);
  const [newInvoiceAmount, setNewInvoiceAmount] = useState(0);
  const [newGstAmount, setNewGstAmount] = useState(0);
  const [authGroupCode, setAuthGroupCode] = useState('');
  const [customHeaderFlag, setCustomHeaderFlag] = useState(false);
  const [redirectToCostAllocation, setRedirectToCostAllocation] = useState(false);
  const [authHistoryFlag, setAuthHistoryFlag] = useState(false)
  const [currencyOption, setCurrencyOption] = useState<any>([])
  const [submitComment, setSubmitComment] = useState(false)
  const [fileName, setFileName] = useState('')
  const [formUnsavedData, setFormUnsavedData] = useState({})

  const navigate = useNavigate();
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const [columnHeaders, setColumnHeaders] = useState({
    companyCode: 'Company Code',
    lineType: 'Line Type',
    jobCode: 'Job Code',
    details: 'Descriptions',
    phaseCode: 'Phase',
    reqCode: 'Requirement',
    accountCode: 'Gl Account',
    amount: 'Amount'
  });

  const handleHeaderChange = (field, newCaption) => {
    setColumnHeaders(prevHeaders => ({
      ...prevHeaders,
      [field]: newCaption
    }));
  };

  const validationSchema = Yup.object({
    // invoiceNo: Yup.string().required('Invoice Number is required'),
    // invoiceDate: Yup.date()
    //   .transform(function (value, originalValue) {
    //     if (this.isType(value)) return value;
    //     const parsedDate = parse(originalValue, 'dd/MM/yyyy', new Date());
    //     return isDate(parsedDate) ? parsedDate : new Date('');
    //   }),
    // .required('Invoice Date is required'),
    // requestedBy: Yup.string().required('Requested By is required'),
    // status: Yup.string().required('Status is required'),
    // vendorCode: Yup.string().required('Vendor Code is required'),
    // poNumber: Yup.string().required('PO Number is required'),
    // authGroup: Yup.string().required('Auth Group is required'),
    // authorizeBy: Yup.string().required('Authorized By is required'),
    // invoiceCurrency: Yup.string().required('Invoice Currency is required'),
    // invoiceAmount: Yup.number()
    // .positive('Amount must be positive')
    // .required('Invoice Amount is required')
  });
  const [initialValue, setInitialValue] = useState({
    invoiceNo: '',
    invoiceDate: getCurrentDate(),
    invoiceRecievedDate: getCurrentDate(),
    requestedBy: '',
    status: '',
    statusDesc: '',
    vendorCode: '',
    vendorName: '',
    poNumber: '',
    authGroup: '',
    authHistory: '',
    authorizeBy: '',
    description: '',
    invoiceAmount: 0,
    invoiceCurrency: '',
    notes: '',
    gstCode: '',
    gstAmount: 0,
    gstRate: ''
  });

  const options = [
    { label: 'USD', value: 'USD' },
    { label: 'AUD', value: 'AUD' }
  ];

  useEffect(() => {
    if (invoiceId !== 0) {
      fetchInvoiceDoc(invoiceId)
      fetchInvoiceData()
      fetchCostAllocationData()
    }
    fetchCurrency()
    fetchStatus()
    fetchVendorDetails()
    fetchAuthGroup()
    fetchGstDetails()
  }, [invoiceId])

  const fetchInvoiceDoc = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await viewInvoice(company, id);
      const contentType = response.headers['content-type'];

      if (response.status === 200) {
        // Ensure response.data is a buffer or array buffer
        const data = new Blob([response.data], { type: contentType });
        const url = URL.createObjectURL(data);
        setFileUrl(url);
        if (contentType === 'application/pdf') {
          setDocumentType('PDF');
        } else if (contentType?.includes("image")) {
          setDocumentType('Image');
        } else {
          // setDocumentVisibility(false);
        }
      } else {
        // setDocumentVisibility(false);
        // toastMessage(t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      setDocumentVisibility(false);
      // toastMessage(t("An error occurred. Please try again later."), "error");
    } finally {
      setLoading(false);
      // setDocumentVisibility(false);
    }
  }, [company, invoiceId]);



  const fetchVendorDetails = async () => {
    try {
      setLoading(true);
      const response = await getvendordropdown(company);
      if (response) {
        setVendorCodeNameMapper(response?.data.data);
        const transformedGroup = response?.data.data.map((item) => ({
          value: item?.code,
          label: `${item?.code} - ( ${item?.name} )`,
          currency: item?.currency,
          taxCode: item?.taxCode
        }));
        setVendorOption(transformedGroup);
      } else {
        setVendorOption([]);
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchAuthorizedByOption = async (value) => {
    try {
      const response = await getAuthGroupby(company, value);
      if (Array.isArray(response.data)) {
        const transformedGroup = response?.data.map((item) => ({
          value: item?.authorizeBy,
          label: `${item?.authorizeByName}`,
        }));
        setAuthorizedByOption(transformedGroup);
      } else {
        setAuthorizedByOption([]);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchPoList = async (value) => {
    try {
      setLoading(false);
      const response = await getpodropdown(company, value);
      if (response) {
        const transformedGroup = response?.data.data.map((item) => ({
          value: item?.poNumber,
          label: `${item?.poNumber} - ${item?.description}`,
        }));
        setPoOPtion(transformedGroup);
      } else {
        setPoOPtion([]);
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchAuthGroup = async () => {
    try {
      const authgroupData = await getAuthGroupForInvoice(company, initialValue.requestedBy);

      if (Array.isArray(authgroupData.data)) {
        const transformedAuthGroup = authgroupData?.data.map((item) => ({
          value: item?.authGroup,
          label: `${item?.authGroup} - ${item?.authDetail}`,
        }));
        setAuthgroupOption(transformedAuthGroup);
      } else {
        setAuthgroupOption([]);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  };


  const fetchStatus = async () => {
    try {
      setLoading(true);
      const response = await getStockStatus(company);
      if (response && response.data) {
        const convertedData = response.data.map(item => ({
          label: item.description,
          value: item.code
        }));
        setStatusOption(convertedData);
      } else {
        toastMessage(response.errorMsg, "error");
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchCurrency = async () => {
    try {
      setLoading(true);
      const response = await getCurrencyOption();
      if (response && response.data) {
        const currencyData = response.data.map(item => ({
          label: item.currencyName,
          value: item.currencyCode
        }));
        setCurrencyOption(currencyData);
      } else {
        toastMessage(response.errorMsg, "error");
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchInvoiceData = async () => {
    try {
      setLoading(true);
      const response = await getInvoiceById(company, invoiceId);
      if (response && response.errorCode === 0 && response.data) {
        const invoiceInfo = response.data;
        if (invoiceInfo.vendorCode !== "") {
          fetchPoList(invoiceInfo.vendorCode)
        }
        if (invoiceInfo.poNumber !== "") {
          userListOption()
        }
        if (invoiceInfo.authGroup !== "") {
          fetchAuthorizedByOption(invoiceInfo.authGroup);
        }
        if (invoiceInfo.invoiceFileName !== "") {
          setFileName(invoiceInfo.invoiceFileName)
        }
        setInitialValue(invoiceInfo);
      }
      if (response && response.errorCode === 0 && response.data && response.data.attachments) {
        setAttachmentList(response.data.attachments);
      }
      if (response && response.errorCode === 1) {
        toastMessage(response.errorMsg, "error")
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchGstDetails = async () => {
    try {
      setLoading(true);
      const response = await getGstDetails(company);
      if (response && response.errorCode === 0 && response.data) {
        setGstResponse(response.data)
        const options = response.data.map(item => ({
          label: item.CODE,
          value: item.CODE
        }));
       setGstOption(options)
      setLoading(false);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const onSelectedFilesChanged = (e) => {
    setSelectedFiles(e.value);
  }

  const onSelectedInvoiceFileChanged = (e) => {
    setSelectedInvoiceDoc(e.value);
  }

  const uploadFileAction = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("companyCode", company);
      formData.append("seqNo", invoiceId);

      if (selectedFiles && selectedFiles.length > 0) {
        selectedFiles.forEach(file => {
          formData.append("attachments", file);
        });
      }
      const response = await uploadAttachments(formData);
      if (response && response.errorCode === 0 && response.data) {
        setAttachmentList(response.data);
        setSelectedFiles([]);

      } else {
        toastMessage(response.errorMsg, "error")
      }
      setUploadFileVisibility(false);
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  };

  const uploadInvoiceAction = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("companyCode", company);
      if (selectedInvoiceDoc) {
        formData.append("file", selectedInvoiceDoc[0]);
      }

      const response = invoiceId !== 0 ? await editInvoiceDocument(formData, invoiceId) : await uploadInvoice(formData);
      if (response && response.errorCode === 0) {
        setSelectedInvoiceDoc(null);
        if (invoiceId !== 0) {
          fetchInvoiceDoc(response.data);
        } else {
          setInvoiceId(response.data);
        }
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setDocumentVisibility(true);
      setUploadInvoiceDocVisibilityFlag(false);
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  };

  const attachmentCheckBoxAction = (value, id) => {
    if (value) {
      setSelectedAttachmentArray(prevArray => [...prevArray, id]);
    } else {
      setSelectedAttachmentArray(selectedAttachmentArray.filter((element) => element !== id && (element)));
    }
  }

  const removeAttachment = async (id) => {
    try {
      setLoading(true);
      const response = await deleteAttachment(company, invoiceId, [id]);
      if (response && response.errorCode === 0 && response.data) {
        setAttachmentList(response.data)
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const deleteSelectedAttachment = async () => {
    try {
      setLoading(true);
      const response = await deleteAttachment(company, invoiceId, selectedAttachmentArray);
      if (response && response.errorCode === 0 && response.data) {
        setAttachmentList(response.data)
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    try {
      const invoice = values.action === "submit" 
        ? { ...values, comments: commentValue } 
        : values;
  
      const formData = new FormData();
      formData.append("companyCode", company);
      formData.append("invoiceJson", JSON.stringify(convertDateFormats(invoice)));
  
      const response = await saveorUpdateInvoice(formData);
  
      if (response && response.data) {
        if (response.data.actualErrorCode === 0) {
          // Success case
          toastMessage(response.data.successMsg, "success");
          if (invoiceId !== 0) {
            if (redirectToCostAllocation) {
              navigate('/costAllocation', { 
                state: { 
                  invoiceNo: values.invoiceNo,
                  invoiceId, 
                  previousPage, 
                  gstAmount: values.gstAmount, 
                  currency: values.invoiceCurrency, 
                  invoiceAmount: cleanNumber(values.invoiceAmount) - cleanNumber(values.gstAmount) 
                } 
              });
            } else if (previousPage === 'approve') {
              navigate('/approveInvoice');
            } else if (previousPage === 'issue') {
              navigate('/createInvoiceBatch');
            } else if (previousPage === 'close') {
              navigate('/unprocessedInvoice');
            } else if(values.action === 'save') {
              fetchInvoiceData()
              fetchCostAllocationData()
            } else {
              navigate('/invoice');
            }
          } else {
            setInvoiceId(response.data.data);
          }
        } else {
          // Error case: Show errorMsg if available
          toastMessage(response.errorMsg || t("An error occurred. Please try again later."), "error");
        }
      }
    } catch (error) {
      // Handle 400 errors gracefully
      if (error.response && error.response.status === 400) {
        const apiErrorMessage = error.response?.errorMsg;
        toastMessage(apiErrorMessage || t("An error occurred. Please try again later."), "error");
      } else {
        // Catch all other errors (e.g., network issues)
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    }
  };
  
  

  const convertDateFormats = (obj) => {
    // Create a new object to avoid mutating the original one
    const newObj = { ...obj };

    for (const key in newObj) {
      // Check if the key should be excluded
      if (Object.prototype.hasOwnProperty.call(newObj, key)) {
        if (key === 'status' || key === 'attachments') {
          // Delete the key if it's "status" or "attachments"
          delete newObj[key];
        } else {
          const value = newObj[key];
          if (typeof value === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
            // Convert date format from "DD/MM/YYYY" to "YYYY-MM-DD"
            const [day, month, year] = value.split('/');
            newObj[key] = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
          } else if (value === null) {
            // Handle null values explicitly (do nothing or assign null back)
            newObj[key] = null;
          } else if (typeof value === 'object') {
            // Recursively convert dates in nested objects
            newObj[key] = convertDateFormats(value);
          }
        }
      }
    }
    return newObj;
  };


  const onApproveAction = async () => {
    try {
      setLoading(true);

      const payload = {
        'notes': commentValue,
        'seqNos': [invoiceId]
      }

      const response = await approveInvoice(payload, company);
      if (response && response.errorCode === 0) {
        toastMessage(t(response.successMsg), "success");
        navigateTo()
      } else {
         // Error case: Show errorMsg if available
         toastMessage(response.errorMsg || t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const apiErrorMessage = error.response?.errorMsg;
        toastMessage(apiErrorMessage || t("An error occurred. Please try again later."), "error");
      } else {
        // Catch all other errors (e.g., network issues)
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    }
    finally {
      setLoading(false);
    }
  }

  const onRejectAction = async () => {
    try {
      setLoading(true);

      const payload = {
        'notes': commentValue,
        'seqNos': [invoiceId]
      }

      const response = await rejectInvoice(payload, company);
      if (response && response.errorCode === 0) {
        toastMessage(t(response.successMsg), "success");
        navigateTo()
      } else {
         // Error case: Show errorMsg if available
         toastMessage(response.errorMsg || t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const apiErrorMessage = error.response?.errorMsg;
        toastMessage(apiErrorMessage || t("An error occurred. Please try again later."), "error");
      } else {
        // Catch all other errors (e.g., network issues)
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    }
    finally {
      setLoading(false);
    }
  }

  const openComment = (value, formData) => {
    setCommentVisibilityFlag(true);
    setActionType(value);
    setFormUnsavedData(formData)
  }

  const commentAdded = async () => {
    setCommentVisibilityFlag(false);
    try {

      const formData = new FormData();
      formData.append("companyCode", company);
      formData.append("invoiceJson", JSON.stringify(convertDateFormats({ ...formUnsavedData, action: 'save' })));
  
      const response = await saveorUpdateInvoice(formData);
  
      if (response && response.data) {
        if (response.data.actualErrorCode === 0) {
          // Success case
          if (actionType === 'approve') {
            onApproveAction()
          } else if (actionType === 'create') {
            onIssueBatch()
          } else if (actionType === 'close') {
            onCloseAction()
          } else {
            onRejectAction()
          }
          } else {
            setInvoiceId(response.data.data);
          }
        } else {
          // Error case: Show errorMsg if available
          toastMessage(response.errorMsg || t("An error occurred. Please try again later."), "error");
        }
    } catch (error) {
      // Handle 400 errors gracefully
      if (error.response && error.response.status === 400) {
        const apiErrorMessage = error.response?.errorMsg;
        toastMessage(apiErrorMessage || t("An error occurred. Please try again later."), "error");
      } else {
        // Catch all other errors (e.g., network issues)
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    }
  }

  const onIssueBatch = async () => {
    try {
      setLoading(true);

      const payload = {
        'notes': commentValue,
        'seqNos': [invoiceId]
      }

      const response = await createInvoiceBatch(payload, company);
      if (response && response.errorCode === 0) {
        toastMessage(t(response.successMsg), "success");
        navigateTo()
      } else {
        toastMessage(response.errorMsg || t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const apiErrorMessage = error.response?.errorMsg;
        toastMessage(apiErrorMessage || t("An error occurred. Please try again later."), "error");
      } else {
        // Catch all other errors (e.g., network issues)
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    }
    finally {
      setLoading(false);
    }
  }

  const onCloseAction = async () => {
    try {
      setLoading(true);

      const payload = {
        'notes': commentValue,
        'seqNos': [invoiceId]
      }

      const response = await closeInvoice(payload, company);
      if (response && response.errorCode === 0) {
        toastMessage(t(response.successMsg), "success");
        navigateTo()
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");

        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const navigateTo = () => {
    if (previousPage === 'create') {
      navigate('/invoice')
    } else if (previousPage === 'approve') {
      navigate('/approveInvoice')
    } else if (previousPage === 'issue') {
      navigate('/createInvoiceBatch')
    } else if (previousPage === 'close') {
      navigate('/unprocessedInvoice')
    }
  }

  const fetchuserList = async (setFieldValue) => {
    try {
      const response = await getUsersDropDown();
      if (response && response.errorCode === 0 && response.data) {
        const transformedUsers = response.data.map(user => ({
          value: user.userid,
          label: `${user.userid} - ${user.username}`
        }));
        setAuthorizedByOption(transformedUsers);
        setFieldValue("authGroup", '')
        setReadAuthGroupFlag(true)
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");

        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const userListOption = async () => {
    try {
      const response = await getUsersDropDown();
      if (response && response.errorCode === 0 && response.data) {
        const transformedUsers = response.data.map(user => ({
          value: user.userid,
          label: `${user.userid} - ${user.username}`
        }));
        setAuthorizedByOption(transformedUsers);
        setReadAuthGroupFlag(true)
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");

        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const fetchPoInfo = async (poNumber, setFieldValue) => {
    try {
      const response = await getPODetails(poNumber, company);
      if (response && response.errorCode === 0 && response.data && response.data.header) {
        // setAuthorizedByOption([{'label': response.data.header.AUTHBY , 'value': response.data.header.AUTHBY}]);
        setFieldValue('authorizeBy', response.data.header.AUTHBY)
      } else {
        setReadAuthGroupFlag(false)
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");

        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }


    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const renderContent = () => {
    return (
      <Formik
        initialValues={{ commentValue: commentValue }}
        onSubmit={(values) => commentAdded()}
      >
        {({ handleChange, handleBlur }) => (
          <Form>
            <Field
              as="textarea"
              name="description"
              placeholder="Comment"
              className="form-control"
              style={{ minHeight: '100px' }}
              value={commentValue}
              onChange={(e) => {
                setCommentValue(e.target.value);
                handleChange(e); // Important to call Formik's handleChange
              }}
              onBlur={handleBlur} // Ensure Formik's blur handling
            />
            <div className="col py-3">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                title={t('Submit')}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                {t('Submit')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  const renderSubmitCommentContent = (Action) => {
    return (
      <Formik
        initialValues={{ commentValue: commentValue }}
        onSubmit={(values) => { setSubmitComment(false); Action(); }}
      >
        {({ handleChange, handleBlur }) => (
          <Form>
            <Field
              as="textarea"
              name="description"
              placeholder="Comment"
              className="form-control"
              style={{ minHeight: '100px' }}
              value={commentValue}
              onChange={(e) => {
                setCommentValue(e.target.value);
                handleChange(e); // Important to call Formik's handleChange
              }}
              onBlur={handleBlur} // Ensure Formik's blur handling
            />
            <div className="col py-3">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                title={t('Submit')}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                {t('Submit')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  const fetchAuthGroupTreeInfo = async (value) => {
    try {
      const response = await getAuthGroupTreeData(value, company);
      
      if (response?.errorCode === 0 && response.data?.length > 0) {
        setAuthGroupData(response.data);
        setAuthGroupVisibility(true);
      } else {
        const errorMsg = response?.errorMsg || "An error occurred. Please try again later.";
        toastMessage(t(errorMsg), "error");
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    } finally {
      setLoading(false);
    }
  };
  
  const authGroupContent = () => (
    authGroupData?.length > 0 && (
      <TreeList
        id="employees"
        dataSource={authGroupData}
        rootValue={0}
        showRowLines
        showBorders
        columnAutoWidth
        keyExpr="urId"
        parentIdExpr="prId"
        autoExpandAll
      >
        <Column dataField="userId" caption="User Id" />
        <Column dataField="userName" caption="User Name" />
      </TreeList>
    )
  );

  const customHeaderView = () => (
    <Box display="flex" flexWrap="wrap" gap={2} mb={2}>
    {Object.entries(columnHeaders).map(([key, value]) => (
      <TextField
        key={key}
        label={`${key} Header`}
        value={value}
        onChange={(e) => handleHeaderChange(key, e.target.value)}
        margin="normal"
        variant="outlined"
      />
    ))}
  </Box>
  )

  

  const attachmentView = (item) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div><CheckBox
        defaultValue={false}
        onValueChanged={(e) => attachmentCheckBoxAction(e.value, item.attachmentId)}
        text={item.fileName} /></div>
      <div>
        <IconButton aria-label="view" size="large" onClick={() => viewAttachment(item)}>
          <VisibilityIcon fontSize="inherit" />
        </IconButton>
        {
          !isViewOnly &&
          <IconButton aria-label="delete" size="large" onClick={() => removeAttachment(item.attachmentId)}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        }
      </div>
    </div>
  )


  const handleRowDeleted = async (e) => {
    try {

      setLoading(true)
      const newAllocationArray = costAllocationData.map((item => item !== e.data && item));
      const response = await saveCostAllocation(newAllocationArray, invoiceId, company, 'delete')
      if (response && response.actualErrorCode === 0) {
        toastMessage(t(response.successMsg), "success");
        fetchCostAllocationData();
      } else {
        // Error case: Show errorMsg if available
        toastMessage(response.errorMsg || t("An error occurred. Please try again later."), "error");
      }
      setLoading(false)
      } catch (error) {
        setLoading(false)
      // Handle 400 errors gracefully
      if (error.response && error.response.status === 400) {
        const apiErrorMessage = error.response?.errorMsg;
        toastMessage(apiErrorMessage || t("An error occurred. Please try again later."), "error");
      } else {
        // Catch all other errors (e.g., network issues)
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    }
  }

  const viewAttachment = async (obj) => {
    try {
      setLoading(true);
      const response = await getDoc(obj.path);
      const contentType = response.headers['content-type'];

      if (response.status === 200) {
        // Ensure response.data is a buffer or array buffer
        const data = new Blob([response.data], { type: contentType });
        const url = URL.createObjectURL(data);
        window.open(url, '_blank');
      } else {
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    } finally {
      setLoading(false);
    }
  }

  const fetchCostAllocationData = async () => {
    try {
      setLoading(true);
      const response = await getCostAllocation(invoiceId, company);
      if (response && response.actualErrorCode === 0) {
        if (response.data && response.data.length > 0) {
          setCostAllocationData(response.data)

          const totalAmount = response.data.reduce((total, item: any) => cleanNumber(total) + cleanNumber(item.amount), 0);

          setTotalCostAllocationAmount(totalAmount);

        } else {
          setCostAllocationData([])
        }
      } else {
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
      setLoading(true);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const updateAllocationRowData = (e: any) => {
    const updateData = costAllocationData.map((elememt: any) => (e.key === elememt.lineNo ? e.data : elememt))
    setCostAllocationData(updateData);
  }

  const onCellPrepared = (e) => {
    if (e.rowType === "data" && (e.column.dataField === "companyCode" || e.column.dataField === "lineType" || e.column.dataField === "jobCode" || e.column.dataField === "accountCode")) {
        e.cellElement.style.backgroundColor = "#EFF2F5"; // Light red
        e.cellElement.style.color = "#721c24"; // Dark red
      }
    }

  const updatePhaseAndRequirementOption = async (e: any) => {
    if (e.column.dataField === 'phaseCode' || e.column.dataField === 'reqCode') {
      if (e.data.lineType === 'GL') {
        e.cancel = true;  // Disable editing if lineType is 'GL'
      } else {
        if (e.column.dataField === 'phaseCode') {
          try {
            const response = await getPhaseOptionByjobCode(company, e.data.jobCode)
            if (response && response.errorCode === 0 && response.data && response.data.length > 0) {
              setPhaseOption(response.data);
            } else {
              setPhaseOption([]);
            }
          } catch (error) {
            toastMessage(t("An error occurred. Please try again later."), "error");
          }
          finally {
            setLoading(false);
          }
        } else {
          if (e.data.phaseCode != '' && e.column.dataField === "reqCode") {
            try {
              const response = await getReqOptionByjobCode(company, e.data.jobCode, e.data.phaseCode)
              if (response && response.errorCode === 0 && response.data && response.data.length > 0) {
                setReqOption(response.data);
              } else {
                setReqOption([]);
              }
            } catch (error) {
              toastMessage(t("An error occurred. Please try again later."), "error");
              setLoading(false);
            }
            finally {
              setLoading(false);
            }
          } else {
            e.cancel = true;
          }
        }
      }
    }
    if (e.column.dataField === 'accountCode') {
      if (e.data.lineType === 'Job Cost') {
        e.cancel = true;  // Disable editing if lineType is 'Job Cost'
      }
    }
    setCostAllocationSaveButton(true);
  }

  const modifyArray = (arr) => {
    return arr.map(item => {
      const { id, ...rest } = item;
      return {
        ...rest,
        seqNo: invoiceId
      };
    });
  };

  const saveCostAllocationData = async () => {
    try {
      const hasInvalidValues = costAllocationData.some(item => !item.amount || isNaN(cleanNumber(item.amount)));

      if (hasInvalidValues) {
        toastMessage(t("Please enter a valid amount for all allocation data"), "error");
        return;  // Stop execution if validation fails
      } else {
        const totalAmount = costAllocationData.reduce((total, item) => cleanNumber(total) + cleanNumber(item.amount), 0);

        if (totalAmount <= cleanNumber(initialValue.invoiceAmount) && totalAmount > 0) {
          setLoading(true)
          const payload = modifyArray(costAllocationData);

          const response = await saveCostAllocation(payload, invoiceId, company, 'edit')
          if (response && response.actualErrorCode === 0) {
            toastMessage(t(response.successMsg), "success");
            fetchCostAllocationData();
          } else {
            if (response.errorMsg) {
              toastMessage(t(response.errorMsg), "error");
            } else {
              toastMessage(t("An error occured Please try again later."), "error");
            }
          }
        } else if(totalAmount < 0) {
          toastMessage(t("The Total amount can't be less than 0"), "error");
        } else {
          toastMessage(t("The Total amount allocated is more than the allocation amount"), "error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <>
      {
        loading && <CustomLoaders />
      }
      <div className="px-10">
        <div className="row h-100">
          <div className={`col-md-${documentVisibility && invoiceId !== 0 ? 6 : 12} d-flex justify-content-center`} style={{ paddingTop: '10px', paddingBottom: '10px', borderRight: '1px solid #ccc' }}>
            <Formik
              enableReinitialize
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
              validateOnChange={true}
              validateOnBlur={true}>
              {({ touched, errors, values, setFieldValue, isSubmitting, isValid, resetForm, submitForm }) => {
                return (
                  <Form className="w-100 p-4">
                    <div className="accordion mt-n2" id="headerCard">
                      <div className="accordion-item">
                        <div className="px-4">
                          <div
                            ref={headerRef}
                            className="accordion-button bg-white cursor-pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#headerData"
                            aria-expanded="true"
                            aria-controls="headerData"
                            onClick={(e) => e.stopPropagation()}>
                            <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                              <div className="col-md-6 d-flex">
                                <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                  {t('Header')} - {invoiceId}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="headerData" className="accordion-collapse collapse show" aria-labelledby="headingTwo" aria-expanded="true" data-bs-parent="#headerCard">
                            <div className="accordion-body">
                              <Card className="py-4">
                                <Row>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Invoice Number')}</label>
                                      <Field
                                        type="text"
                                        name="invoiceNo"
                                        placeholder={t('Invoice Number')}
                                        value={values.invoiceNo}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('invoiceNo', e.target.value)}
                                      />
                                      {errors?.invoiceNo && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.invoiceNo}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Invoice Date')}</label>
                                      <Field
                                        type="date"
                                        name="invoiceDate"
                                        placeholder={t('Invoice Date')}
                                        value={
                                          values.invoiceDate && values.invoiceDate.includes('/')
                                            ? format(parse(values.invoiceDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
                                            : values.invoiceDate // in case it's already in the correct format
                                        }
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('invoiceDate', e.target.value)}
                                      />
                                      {errors?.invoiceDate && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.invoiceDate}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Invoice Recieved Date')}</label>
                                      <Field
                                        type="date"
                                        name="invoiceRecievedDate"
                                        placeholder={t('Invoice Recieved Date')}
                                        value={
                                          values.invoiceRecievedDate && values.invoiceRecievedDate.includes('/')
                                            ? format(parse(values.invoiceRecievedDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
                                            : values.invoiceRecievedDate// in case it's already in the correct format
                                        }
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('invoiceRecievedDate', e.target.value)}
                                      />
                                      {errors?.invoiceDate && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.invoiceRecievedDate}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Requested By')}</label>
                                      <Field
                                        type="text"
                                        name="requestedBy"
                                        placeholder={t('Requested By')}
                                        value={values.requestedBy ? values.requestedBy : userName}
                                        autoComplete="false"
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('requestedBy', e.target.value)} />
                                      {errors?.requestedBy && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                          {errors?.requestedBy}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Status')}</label>
                                      <Field
                                        type="text"
                                        name="statusDesc"
                                        placeholder={t('Status')}
                                        value={values.statusDesc}
                                        autoComplete="false"
                                        className={"form-control disable"}
                                        disabled={true}
                                        onChange={(e) => setFieldValue('statusDesc', e.target.value)} />
                                      {errors?.statusDesc && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                          {errors?.statusDesc}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <SelectInput 
                                        optionList={vendorOption}
                                        isViewOnly={isViewOnly}
                                        onChangeAction={(selectedOption) => {
                                          const selectedValue = selectedOption ? selectedOption.value : null;
                                            setFieldValue("vendorCode", selectedValue);
                                            const vendorName = vendorCodeNameMapper.filter((item) => item.code === selectedValue && (item));
                                            setFieldValue("vendorName", vendorName[0]['name']);
                                            setFieldValue('poNumber', '');
                                            setReadAuthGroupFlag(false);
                                            fetchPoList(selectedValue);
                                            if(selectedOption.invoiceCurrency !== '') {
                                              setFieldValue("invoiceCurrency", selectedOption.currency)
                                            }
                                            if(selectedOption.taxCode !== "") {
                                              setFieldValue("gstCode", selectedOption.taxCode) 
                                              const selectedObject = gstRespose.find((item) => item.CODE === selectedOption.taxCode);
                                              setFieldValue("gstRate", selectedObject.TAX_PERCNT)
                                            }
                                        }}
                                        defaultValue={vendorOption.find((option) => option.value === values.vendorCode) || null}
                                        errorMsg={errors?.vendorCode || null}
                                        clearAction={() => {setFieldValue("vendorCode", ""); setFieldValue("vendorName", "")}}
                                        labelText={t('Vendor Code')}
                                        placeholderText={t("Select Vendor Code")}/>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Vendor Name')}</label>
                                      <Field
                                        type="Name"
                                        name="vendorName"
                                        placeholder={t('Vendor Name')}
                                        value={values.vendorName}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={true}
                                      />
                                      {errors?.vendorName && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.vendorName}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <SelectInput 
                                        optionList={poOPtion}
                                        isViewOnly={isViewOnly || poOPtion.length === 0}
                                        onChangeAction={(selectedOption) => {
                                          const selectedValue = selectedOption ? selectedOption.value : null;
                                          fetchuserList(setFieldValue);
                                          fetchPoInfo(selectedValue, setFieldValue);
                                          setFieldValue("poNumber", selectedValue);
                                        }}
                                        defaultValue={poOPtion.find((option) => option.value === values.poNumber) || null}
                                        errorMsg={errors?.poNumber || null}
                                        clearAction={() => {setFieldValue("poNumber", ""); setReadAuthGroupFlag(false)}}
                                        labelText={t('PO Number')}
                                        placeholderText={t("Select Po Number")}/>
                                  </Col>
                                  <Col sm={4}>
                                    <SelectInput 
                                        optionList={authgroupOption}
                                        isViewOnly={isViewOnly || readAuthGroupFlag}
                                        onChangeAction={(selectedOption) => {
                                          const selectedValue = selectedOption ? selectedOption.value : null;
                                          setFieldValue("authGroup", selectedValue);
                                          setAuthGroupCode(selectedValue)
                                          fetchAuthorizedByOption(selectedValue)
                                        }}
                                        defaultValue={authgroupOption.find((option) => option.value === values.authGroup) || null}
                                        errorMsg={errors?.authGroup || null}
                                        clearAction={() => {setFieldValue("authGroup", ""); setFieldValue("authorizeBy", "")}}
                                        labelText={t('Auth Group')}
                                        placeholderText={t("Select Auth Group")}/>
                                  </Col>
                                  <Col sm={4}>
                                    <SelectInput 
                                        optionList={authorizedByOption.some(option => option.value === values.authorizeBy)
                                          ? authorizedByOption
                                          : [...authorizedByOption, { value: values.authorizeBy, label: values.authorizeBy }]}
                                        isViewOnly={isViewOnly || authorizedByOption.length === 0 || readAuthByFlag}
                                        onChangeAction={(selectedOption) => {
                                          const selectedValue = selectedOption ? selectedOption.value : null;
                                          setFieldValue("authorizeBy", selectedValue);
                                        }}
                                        defaultValue={authorizedByOption.find((option) => option.value === values.authorizeBy) || { value: values.authorizeBy, label: values.authorizeBy }}
                                        errorMsg={errors?.authorizeBy || null}
                                        clearAction={() => {setFieldValue("authorizeBy", "")}}
                                        labelText={t('Authorized By')}
                                        placeholderText={t("Select Authorized By")}/>
                                  </Col>
                                  <Col sm={4}>
                                  <SelectInput 
                                        optionList={currencyOption}
                                        isViewOnly={isViewOnly}
                                        onChangeAction={(selectedOption) => {
                                          const selectedValue = selectedOption ? selectedOption.value : null;
                                          setFieldValue("invoiceCurrency", selectedValue);
                                        }}
                                        defaultValue={currencyOption.find((option) => option.value === values.invoiceCurrency) || null}
                                        errorMsg={errors?.invoiceCurrency || null}
                                        clearAction={() => {setFieldValue("invoiceCurrency", "")}}
                                        labelText={t('Invoice Currency')}
                                        placeholderText={t("Select Invoice Currency")}/>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Amount (Inc. Tax)')}</label>
                                      <Field name="invoiceAmount">
                                        {({ field, form }) => (
                                          <NumberBox
                                            format="#,##0.00"
                                            value={cleanNumber(values.invoiceAmount) || 0} // Use `value` instead of `defaultValue`
                                            placeholder={t('Amount (Inc. Tax)')}
                                            stylingMode="outlined"
                                            height="40px"
                                            disabled={isViewOnly || previousPage === "issue"}
                                            min={0}
                                            onValueChanged={(e) => {form.setFieldValue(field.name, e.value); setNewInvoiceAmount(e.value)}}
                                            style={{
                                              backgroundColor: (isViewOnly || previousPage === "issue") ? '#e9ecef' : '#fff',
                                              opacity: (isViewOnly || previousPage === "issue") ? 1 : undefined,
                                              pointerEvents: (isViewOnly || previousPage === "issue") ? 'none' : undefined
                                            }}/>
                                        )}
                                      </Field>
                                      {errors?.invoiceAmount && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.invoiceAmount}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                      <SelectInput 
                                      optionList={gstOption}
                                      isViewOnly={isViewOnly}
                                      onChangeAction={(selectedOption) => {
                                        console.log("Akash in ")
                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                        const selectedObject = gstRespose.find((item) => item.CODE === selectedValue);
                                        setFieldValue("gstCode", selectedValue);
                                        setFieldValue("gstRate", selectedObject.TAX_PERCNT)
                                      }}
                                      defaultValue={gstOption.find((option) => option.value === values.gstCode) || null}
                                      errorMsg={errors?.gstCode || null}
                                      clearAction={() => {
                                        setFieldValue("gstCode", "");
                                        setFieldValue("gstRate", "");
                                      }}
                                      labelText={t('Tax Code')}
                                      placeholderText={t("Select Tax Code")}/>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Tax Percentage')}</label>
                                      <Field name="gstRate">
                                        {({ field, form }) => (
                                          <NumberBox
                                            format="#,##0.00"
                                            value={cleanNumber(values.gstRate) || 0} // Use `value` instead of `defaultValue`
                                            placeholder={t('Tax Percentage')}
                                            stylingMode="outlined"
                                            height="40px"
                                            disabled={true}
                                            min={0}
                                            style={{
                                              backgroundColor: '#e9ecef',
                                              opacity: 1,
                                              pointerEvents: 'none'
                                            }}/>
                                        )}
                                      </Field>
                                      {errors?.gstRate && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.gstRate}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Tax Amount')}</label>
                                      <Field name="gstAmount">
                                        {({ field, form }) => (
                                          <NumberBox
                                            format="#,##0.00"
                                            value={cleanNumber(values.gstAmount) || 0} // Use `value` instead of `defaultValue`
                                            placeholder={t('Tax Amount')}
                                            stylingMode="outlined"
                                            height="40px"
                                            disabled={isViewOnly || previousPage === "issue"}
                                            min={0}
                                            onValueChanged={(e) => {form.setFieldValue(field.name, e.value); setNewGstAmount(e.value)}}
                                            style={{
                                              backgroundColor: (isViewOnly || previousPage === "issue") ? '#e9ecef' : '#fff',
                                              opacity: (isViewOnly || previousPage === "issue") ? 1 : undefined,
                                              pointerEvents: (isViewOnly || previousPage === "issue") ? 'none' : undefined
                                            }}/>
                                        )}
                                      </Field>
                                      {errors?.gstAmount && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.gstAmount}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Auth History')}</label>
                                      <Field
                                        type="text"
                                        name="authHistory"
                                        placeholder={t('Auth History')}
                                        value={values.authHistory}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={true}
                                        onClick={()=>setAuthHistoryFlag(true)}
                                      />
                                      <div
                                          onClick={() => setAuthHistoryFlag(true)}
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            cursor: "pointer",
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      {errors?.authHistory && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.authHistory}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={6}></Col>
                                  <Col sm={6}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Invoice Description')}</label>
                                      <Field
                                        as="textarea"
                                        name="description"
                                        placeholder={t('Invoice Description')}
                                        value={values.description}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('description', e.target.value)}
                                        style={{ minHeight: '100px' }} // Adjust size as needed
                                      />
                                      {errors?.description && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.description}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Comments')}</label>
                                      <Field
                                        as="textarea"
                                        name="notes"
                                        placeholder={t('Comments')}
                                        value={values.notes}
                                        className={"form-control disable"}
                                        disabled={true}
                                        style={{ minHeight: '100px' }} // Adjust size as needed
                                      />
                                      {errors?.description && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.description}</div>
                                      )}
                                    </div>
                                  </Col>
                                  {
                                    invoiceId !== 0 &&
                                    <>
                                      <Col sm={4}>
                                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Attach Document')}</label><br />
                                        <Button
                                          component="label"
                                          role={undefined}
                                          variant="contained"
                                          tabIndex={-1}
                                          startIcon={<CloudUploadIcon />}
                                          disabled={isViewOnly}
                                          onClick={() => setUploadFileVisibility(prev => !prev)}
                                        >
                                          {t("Upload File")}
                                        </Button>
                                      </Col>

                                      <Col sm={4}>
                                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Invoice Document')}</label><br />
                                        <Button
                                          component="label"
                                          role={undefined}
                                          variant="contained"
                                          tabIndex={-1}
                                          startIcon={<CloudUploadIcon />}
                                          disabled={isViewOnly}
                                          onClick={() => setUploadInvoiceDocVisibilityFlag(prev => !prev)}
                                        >
                                          {t("Upload Invoice")}
                                        </Button>

                                      </Col>
                                    </>
                                  }
                                      <Col sm={4}>
                                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Show Auth Group')}</label><br />
                                        <Button
                                          component="label"
                                          role={undefined}
                                          variant="contained"
                                          tabIndex={-1}
                                          // startIcon={<CloudUploadIcon />}
                                          disabled={false}
                                          onClick={() => (initialValue.authGroup != '' || authGroupCode != '' ) && fetchAuthGroupTreeInfo(initialValue.authGroup != '' ? initialValue.authGroup : authGroupCode)}
                                        >
                                          {t("Show Auth Group")}
                                        </Button>

                                      </Col>
                                </Row>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion mt-5" id="costAllocation">
                      <div className="accordion-item">
                        <div className="px-4">
                          <div
                            ref={headerRef}
                            className="accordion-button bg-white cursor-pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#costAllocationData"
                            aria-expanded="true"
                            aria-controls="costAllocationData"
                            onClick={(e) => e.stopPropagation()}>
                            <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                              <div className="col-md-6 d-flex">
                                <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                  {t('Cost Allocation')} - {invoiceId}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="costAllocationData" className="accordion-collapse collapse" aria-labelledby="headingTwo" aria-expanded="true" data-bs-parent="#costAllocation">
                            <div className="accordion-body">
                              <Card className="p-4">
                                {
                                  (initialValue && initialValue.invoiceAmount && initialValue.invoiceAmount != 0 && (cleanNumber(totalCostAlocationAmount) < (cleanNumber(initialValue.invoiceAmount)-cleanNumber(initialValue.gstAmount))) && (initialValue.invoiceAmount == newInvoiceAmount) && (initialValue.gstAmount == newGstAmount)) &&  
                                  <div className="d-flex justify-content-end mb-3">
                                    <div className="col-md-3">
                                      <button
                                        className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100 h-45px"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (previousPage === 'issue') {
                                            navigate('/costAllocation', {
                                              state: {
                                                invoiceNo: initialValue.invoiceNo,
                                                invoiceId,
                                                previousPage,
                                                gstAmount: initialValue.gstAmount,
                                                currency: initialValue.invoiceCurrency,
                                                invoiceAmount: formatPrice(cleanNumber(initialValue.invoiceAmount) - cleanNumber(initialValue.gstAmount)),
                                              },
                                            });
                                          } else {
                                            setRedirectToCostAllocation(true);
                                            setFieldValue('action', 'save');
                                            submitForm();
                                          }
                                        }}
                                        title={t('New')}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                                        <FaPlus style={{ marginRight: "5px" }} size={15} />
                                        {t('New')}
                                      </button>
                                    </div>
                                  </div>
                                }
                                {/* <div className="d-flex justify-content-end">
                                    <div className="col-md-3">
                                      <button
                                        className="btn text-white rounded-pill text-wrap fs-6 primary-button w-100 h-45px my-3"
                                        onClick={(e) => {e.preventDefault(); setCustomHeaderFlag(true)}}
                                        title={t('Customize')}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                                        {t('Customize')}
                                      </button>
                                    </div>
                                  </div> */}
        
                
              <DataGrid
        showBorders={true}
        keyExpr="lineNo"
        allowColumnResizing={true}
        columnResizingMode={'widget'}
        allowColumnReordering={true}
        onRowUpdated={updateAllocationRowData}
        onRowRemoved={handleRowDeleted}
        onEditingStart={updatePhaseAndRequirementOption}
        dataSource={costAllocationData}
        onCellPrepared={onCellPrepared}
        onEditorPreparing={(e) => {
          if (e.parentType === 'dataRow' && (e.dataField === 'phaseCode' || e.dataField === 'reqCode')) {
            e.editorOptions = {
              ...e.editorOptions,
              dropDownOptions: {
                width: 300, // Set custom dropdown width
              },
            };
          }
        }}
      >
        <HeaderFilter visible={true} />
        <SearchPanel visible={false} width={240} placeholder="Search..." />

        <Column type="buttons" width={80} buttons={['delete']} caption="Actions" />
        <Column dataField="companyCode" caption={columnHeaders.companyCode} width={120} allowEditing={false} />
        <Column dataField="lineType" caption={columnHeaders.lineType} width={120} allowEditing={false} />
        <Column dataField="accountCode" caption={columnHeaders.accountCode} width={150} allowEditing={false} />
        <Column dataField="jobCode" caption={columnHeaders.jobCode} width={100} allowEditing={false}/>        
        <Column dataField="phaseCode" caption={columnHeaders.phaseCode} width={150} calculateDisplayValue="phaseCode">
          <Lookup dataSource={phaseOption} displayExpr={(item) => item ? `${item.phaseCode} - ${item.description}` : ''} valueExpr="phaseCode" />
        </Column>
        <Column dataField="reqCode" caption={columnHeaders.reqCode} width={150} calculateDisplayValue="reqCode">
          <Lookup dataSource={reqOption} displayExpr={(item) => item ? `${item.reqCode} - ${item.description}` : ''} valueExpr="reqCode" />
        </Column>
        <Column dataField="details" caption={columnHeaders.details} width={350} />
        <Column dataField="amount" caption={columnHeaders.amount} format="#,##0.00" width={150} dataType="number">
          <ValidationRule type="numeric" />
        </Column>

        <Editing mode="cell" allowUpdating={true} allowDeleting={true} />

        <Summary>
          <TotalItem
            column="amount"
            summaryType="sum"
            displayFormat="Total: {0}"
            valueFormat="#,##0.00"
          />
        </Summary>
      </DataGrid>
      {/* </div> */}
              {
                costAllocationSaveButton &&
                <Row className="mt-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
                <div className="col-md-2 mt-3">
                          <button
                            className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                            type="button"
                            onClick={(e) => { e.preventDefault(); saveCostAllocationData(); }} // Call handleSubmit with 'save' action
                            title={t('Save')}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                            {t('Save')}
                          </button>
                        </div>
              </Row>
              }

                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion mt-5" id="attachedDocument">
                      <div className="accordion-item">
                        <div className="px-4">
                          <div
                            ref={headerRef}
                            className="accordion-button bg-white cursor-pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#attachedDocumentData"
                            aria-expanded="true"
                            aria-controls="attachedDocumentData"
                            onClick={(e) => e.stopPropagation()}>
                            <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                              <div className="col-md-6 d-flex">
                                <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                  {t('Attached Document')} - {invoiceId}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="attachedDocumentData" className="accordion-collapse collapse" aria-labelledby="headingTwo" aria-expanded="true" data-bs-parent="#attachedDocument">
                            <div className="accordion-body">
                              <Card className="p-4">
                                <List
                                  dataSource={attachmentList}
                                  height="100%"
                                  itemRender={attachmentView} />

                                {
                                  selectedAttachmentArray && selectedAttachmentArray.length > 0 &&
                                  <Button variant="outlined" className="col-2" startIcon={<DeleteIcon />} onClick={deleteSelectedAttachment}>
                                    Delete
                                  </Button>
                                }
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      
                      <Row className="mt-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
                        {
                          invoiceId !== 0 &&
                          <div className="col-md-2 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              onClick={() => setDocumentVisibility(prev => !prev)}
                              title={documentVisibility ? t('Hide Document') : t('Show Document')}
                              type="button"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {documentVisibility ? t('Hide Document') : t('Show Document')}
                            </button>
                          </div>
                        }
                        {
                          !isViewOnly &&
                          <>
                          <div className="col-md-2 mt-3">
                          <button
                            className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                            onClick={() => {
                              if (previousPage === 'create') {
                                resetForm();  // Reset the form
                              } else if (previousPage === 'issue') {
                                navigate('/createInvoiceBatch');  // Navigate to the create invoice batch page
                              } else {
                                navigate('/approveInvoice');  // Navigate to the approve invoice page
                              }
                            }} title={t(previousPage === 'create' ? 'Reset' : 'Cancel')}
                            type="button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0a7eac",
                            }}
                          >
                            {t(previousPage === 'create' ? 'Reset' : 'Cancel')}
                          </button>
                        </div>
                       
                          <div className="col-md-2 mt-3">
                          <button
                            className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                            type="button"
                            onClick={(e) => { e.preventDefault(); setFieldValue('action', 'save'); submitForm() }} // Call handleSubmit with 'save' action
                            title={t('Save')}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0a7eac",
                            }}
                          >
                            {t('Save')}
                          </button>
                        </div>
                        
                        
                        {
                          previousPage === 'approve' &&
                          <>
                            <div className="col-md-2 mt-3">
                              <button
                                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                                type="button"
                                onClick={() => openComment('approve', values)}
                                title={t('Approve')}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#0a7eac",
                                }}
                              >
                                {t('Approve')}
                              </button>
                            </div>
                            <div className="col-md-2 mt-3">
                              <button
                                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                                type="button"
                                onClick={() => openComment('reject', values)}
                                title={t('Reject')}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#0a7eac",
                                }}
                              >
                                {t('Reject')}
                              </button>
                            </div>
                          </>
                        }
                        {
                          previousPage === 'issue' &&
                          <div className="col-md-3 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={() => openComment('create', values)}
                              title={t('Create Invoice Batch')}
                              // type="submit"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Create Invoice Batch')}
                            </button>
                          </div>
                        }
                        {
                          previousPage === 'close' &&
                          <div className="col-md-4 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={() => openComment('close', values)}
                              title={t('Close Invoice')}
                              // type="submit"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Close Invoice')}
                            </button>
                          </div>
                        }
                        {
                          previousPage === 'create' &&
                          <div className="col-md-2 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={(e) => { e.preventDefault(); setFieldValue('action', 'submit'); setSubmitComment(true);}} // Call handleSubmit with 'submit' action
                              title={t('Submit')}
                              // type="submit"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Submit')}
                            </button>
                          </div>
                        }
                          </>
                        }
                      </Row>
                    }

                    <UploadFile
                      visibilityFlag={uploadFileVisibility}
                      title={t("Upload Attachments")}
                      onHideFunction={() => setUploadFileVisibility(false)}
                      multipleFlag={true}
                      height={400}
                      fileExtensions={fileExtensions}
                      onSelectedFilesChanged={onSelectedFilesChanged}
                      onUploadAction={uploadFileAction} />

                    <UploadFile
                      visibilityFlag={uploadInvoiceDocVisibilityFlag}
                      title={t("Upload invoice")}
                      onHideFunction={() => setUploadInvoiceDocVisibilityFlag(false)}
                      multipleFlag={true}
                      fileExtensions={fileExtensions}
                      onSelectedFilesChanged={onSelectedInvoiceFileChanged}
                      onUploadAction={uploadInvoiceAction} />

                    <Popup
                      visible={commentVisibilityFlag}
                      hideOnOutsideClick={true}
                      onHiding={(e) => setCommentVisibilityFlag(false)}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title={t('Comment')}
                      width={335}
                      height={280}
                      contentRender={renderContent} />
                    {
                      authGroupData && authGroupData.length > 0 &&
                      <Popup
                      visible={authGroupVisibility}
                      hideOnOutsideClick={true}
                      onHiding={(e) => setAuthGroupVisibility(false)}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title={t('Auth Group')}
                      contentRender={authGroupContent} />
                    }
                    {
                     submitComment &&
                      <Popup
                      visible={submitComment}
                      hideOnOutsideClick={true}
                      onHiding={(e) => setSubmitComment(false)}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title={t('Comment')}
                      width={335}
                      height={280}
                      contentRender={() => renderSubmitCommentContent(() => submitForm())} />
                    }
                     
                      <Popup
                      visible={customHeaderFlag}
                      hideOnOutsideClick={true}
                      onHiding={(e) => setCustomHeaderFlag(false)}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title={t('Customize Header')}
                      contentRender={customHeaderView} />
                      <Popup
                      visible={authHistoryFlag}
                      hideOnOutsideClick={true}
                      onHiding={(e) => setAuthHistoryFlag(false)}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title={t('Auth History')}
                      width={335}
                      height={280}
                      contentRender={() => (
                        <div>
                          {initialValue.authHistory
                            ? initialValue.authHistory
                            : t('No History Available')}
                        </div>
                      )}/>                      
                  </Form>
                )
              }}
            </Formik>
          </div>
          {
            documentVisibility && invoiceId !== 0 && (
              <div className="col-md-6 d-flex align-items-center" style={{ padding: '10px' }}>
                {documentType === 'PDF' ? (<PdfViewer fileUrl={fileUrl} fileName={fileName} />) : (<ImageViewer fileUrl={fileUrl} fileName={fileName}/>)}
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}
