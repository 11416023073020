import React, { useState } from 'react';
import { getDoc } from '../modules/apps/user-management/users-list/core/_requests';
import { toastMessage } from '../modules/auth/functions/toastMessage';
import { useTranslation } from 'react-i18next';
import AttachmentIcon from '@mui/icons-material/Attachment';

interface HyperlinkRendererProps {
  value: string;
}

const HyperlinkRenderer: React.FC<HyperlinkRendererProps> = (params) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileName = params.value.split("\\").pop();
  const path = params.value;
  const { t } = useTranslation();

  const handleDocClick = async () => {
    setIsLoading(true);
    try {
      const response = await getDoc(path);
      const contentType = response.headers['content-type'];
      if (response.status === 200) {
        const data = new Blob([response.data], { type: response.headers['content-type'] });
        const url = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        if (fileName) {
          // Check if fileName is defined
          const fileExtension = fileName.split(".").pop();
          if (
            fileExtension === "pdf" ||
            contentType?.includes("image")
          ) {
            // If the file is a PDF or an image, open it in a new tab
            link.target = "_blank";
          } else {
            // If the file is not a PDF or an image, make it downloadable
            link.download = fileName;
          }
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      else{
        toastMessage(t("Failed to Load the document Please try again later."), "error");
      }
      setIsLoading(false);
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error")
      setIsLoading(false);
    }
  };

  return params.value ? (
    <AttachmentIcon
      fontSize="large"
      style={{ cursor: "pointer" }}
      onClick={handleDocClick}
    />
  ) : null;

  // return (
  //   <>
  //     // <a
  //     //   onClick={handleDocClick}
  //     //   style={{ cursor: "pointer", color: "#1e90ff" }}
  //     // >
  //     //   {isLoading ? <div>Loading...</div> : params.value}
  //     // </a>
  //   </>
  // );
};

export default HyperlinkRenderer;
